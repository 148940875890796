import React from 'react';

import './styles.css';

type Props = {
  padding: number;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const PaddedBlock: React.FC<Props> = ({ padding }: Props) => {
  return <div style={{ paddingTop: `${padding}px` }} />;
};

export default PaddedBlock;
