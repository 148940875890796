import React from "react";
import ContentConverter from "../../../utils/contentConverter";

import "./styles.css";

export type TableRow = Array<
  string | { beforeText?: string; rows?: TableRow[]; afterText?: string }
>;

type Props = {
  heading: string;
  rows: TableRow[];
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const Table: React.FC<Props> = (props: Props) => {
  const { heading, rows } = props;

  const getConvertedText = (content: string) =>
    new ContentConverter(content).nlToBr().convertLinks().getReactHtml();

  return (
    <div className="Table">
      <h3 className="heading">{heading}</h3>
      <div className="tableInner">
        <table>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                {row.map((column, index) =>
                  typeof column === "string" ? (
                    <td
                      key={index}
                      dangerouslySetInnerHTML={getConvertedText(column)}
                    />
                  ) : (
                    <td key={index}>
                      {column.beforeText && <span>{column.beforeText}</span>}
                      {column.rows && (
                        <table>
                          <tbody>
                            {column.rows.map((innerRow, innerRowIndex) => (
                              <tr key={`inner-row-${innerRowIndex}`}>
                                {innerRow.map(
                                  (innerColumn, innerColumnindex) => (
                                    <td
                                      key={`inner-column-${innerColumnindex}`}
									  dangerouslySetInnerHTML={getConvertedText(innerColumn as string)}
                                    ></td>
                                  )
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
					  {column.afterText && <span className="afterText">{column.afterText}</span>}
                    </td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
