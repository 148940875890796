import React from "react";
import { OptionType } from "../../../utils/types";

import "./styles.css";

type Props = {
  key: string;
  option: OptionType;
  selected: OptionType | null;
  onSelect: (optionKey: string) => void;
};

const Option: React.FC<Props> = (props: Props) => {
  const { selected, option } = props;

  const onSelect = (e: React.SyntheticEvent) => {
    e.preventDefault();
    props.onSelect(props.option.key);
  };

  return (
    <li
      className={`option ${selected?.key === option.key ? "selected" : ""}`}
      onClick={onSelect}
    >
      {option.value}
    </li>
  );
};

export default Option;
