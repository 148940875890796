import classNames from 'classnames';
import React, { Component } from 'react';
import './styles.css';

type Props = {
	isOpen: boolean
};
/**
 * AccordionButton
 *
 * @typedef {Object} Props
 * @prop {boolean} [isOpen]
 *
 * @augments {Component<Props>}
 */

export default class AccordionButton extends Component<Props> {

	genClassName = (props: Props) =>
		classNames('AccordionButton', { AccordionButtonOpen: props.isOpen });

	render () {
		const { isOpen } = this.props;
		return (
			<button className={this.genClassName(this.props)}>{isOpen}</button>
		);
	}
}
