import React, { createContext } from 'react';

const { Consumer, Provider } = createContext({ content: {} });

export const withLanguageContext = (Component: React.ReactType) => (props: object) => (
    <Consumer>
        {({ content }) => <Component {...props} content={content} />}
    </Consumer>
);

export const LanguageContextProvider = Provider;
