import React, { Component } from 'react';
import { PaddedBlock } from './components/atoms';
import { HeaderView, FooterView, LearnMoreView,} from './components/views';

class BrochurePage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main>
        <HeaderView opaque={true} />
        <PaddedBlock padding={80} />
        <LearnMoreView />
        <FooterView />
      </main>
    );
  }
}

export default BrochurePage;
