import React from 'react';

import './styles.css';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { VideoSection } from '../../templates';

type Props = {
    content: {
        solutionsPage: {
            iFlyThereVideoView: {
                heading: string;
                content: string;
                heading1: string;
                heading2: string;
                videoId: string;
                subContent: string
            }
        }
    }
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const IFlyThereVideoView: React.FC<Props> = (props: Props) => {

    const {
        heading,
        content,
        subContent,
        videoId,
    } = props.content.solutionsPage.iFlyThereVideoView;

    return (
        <VideoSection
            {...{
                heading,
                content,
                videoId,
                subContent
            }}
            className="iFlyThereVideoView"
        />
    );

};

export default withLanguageContext(IFlyThereVideoView);
