import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { genClassName } from '../../../utils/helpers';

type Props = {
    className: string;
    onClick?: () => void;
    href?: string; 
    children: React.ReactNode;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class ArrowLink extends Component<Props, {}> {

    handleClick = () => this.props.onClick && this.props.onClick();

    render () {
        const { href } = this.props;

        return (
            href
                ? <Link className={genClassName('ArrowLink', this.props.className)} to={href}>
                    <span>{this.props.children}</span>
                </Link>
                : <button className={genClassName('ArrowLink', this.props.className)} onClick={this.handleClick}>
                    <span>{this.props.children}</span>
                </button>
        );
    }

}
