import React, { Component, Fragment } from 'react';
import { Button, Input, SimpleMarkdown, FormValidationMessage, Form } from '../../atoms';
import { REGEX } from '../../../constants/index';
import  { ContactFormType } from '../../../utils/types';
import  { contactRequest } from '../../../sevices/DOCBrowserApi';
import './styles.css';

type Props = {
    buttonLabel: string;
    emailLabel: string;
    firstNameLabel: string;
    lastNameLabel: string;
    messageLabel: string;
    validationMessage: string;
    termsContent: string;
    serverErrorContent: string;
    serverErrorMessage: string;
    serverSuccessContent: string;
    serverSuccessMessage: string;
    backLabel: string;
};

type State = {
    formKey: number;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class ContactForm extends Component<Props, State> {

    state = {
        formKey: 0,
    }

    handleSuccess = () => {
        this.setState(({ formKey }) => ({
            formKey: ++formKey,
        }));
    };

    renderForm = ({
        buttonLabel,
        emailLabel,
        firstNameLabel,
        lastNameLabel,
        messageLabel,
        // organizationLabel,
        // organizationRoleLabel,
        termsContent,
        validationMessage,
    }: Props, state: ContactFormType): React.ReactNode => (
        <Fragment>
            {state.errors.length
                ? <FormValidationMessage
                    errors={state.errors}
                    validationMessage={validationMessage}
                />
                : null
            }
            <div className='ContactForm-split'>
                <Input
                    autoComplete='given-name'
                    label={firstNameLabel}
                    name='first_name'
                    pattern={REGEX.name}
                    required={true}
                    type='text'
                    animated={true}
                />
                <Input
                    autoComplete='family-name'
                    label={lastNameLabel}
                    name='last_name'
                    pattern={REGEX.name}
                    required={true}
                    type='text'
                    animated={true}
                />
            </div>
            <Input
                autoComplete='email'
                label={emailLabel}
                name='email'
                required={true}
                type='email'
                animated={true}
            />
            <Input
                label={messageLabel}
                name='message'
                required={true}
                rows={5}
                type='textarea'
                animated={true}
            />
            <SimpleMarkdown>{termsContent}</SimpleMarkdown>
            <Button
                className='ContactForm-button'
                type='submit'
                submitting={state.isSubmitting}
            >{buttonLabel}</Button>
        </Fragment>
    );

    render () {
        return (
            <div className='ContactForm'>
                <Form key={this.state.formKey}
                    {...this.props}
                    submitHandler={contactRequest}
                    onSuccess={this.handleSuccess}
                    render={(state) => this.renderForm(this.props, state)}
                />
            </div>
        );
    }

}
