import React, { Component, Fragment } from 'react';

import AccordionItem from '../../molecules/AccordionItem';
import FaqHeading from '../../molecules/FaqHeading';
import { Question } from '../../../utils/types';

import './styles.css';

type Props = {
	content: {
		faq: {
			questions: Question[],
			heading: {
				title: string,
				subTitle: string
			}

		}
	};
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {Object} content
 *
 * @augments {Component<Props>}
 */
export default class Faq extends Component<Props> {

	render () {
		const { questions, heading } = this.props.content.faq;
		const { title, subTitle } = heading;
		return (
			<Fragment>
				<div className='faqPadded'>
					<FaqHeading heading={title} subheading={subTitle} />
					{questions.map(({ question, answer }, index: number) => (
						<AccordionItem
							// eslint-disable-next-line react/no-array-index-key
							key={index}
							question={question}
							answer={answer}
						/>
					))}
				</div>
			</Fragment>
		);
	}
}
