import React from 'react';
import { TextImageSecond } from '../../templates';
import { withLanguageContext } from '../../../utils/LanguageContext';
import './styles.css';

type Props = {
    content: {
        landingPage: {
            freeTrial: {
                buttonLabel: string;
                content: string;
                heading: string;
                href: string;
                subheading: string;
                underButtonText: string
            }
        }
    }
};
/**
 * Free trail view on landing page
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const FreeTrialView: React.FC<Props> = (props) => {
    const {
        buttonLabel,
        content,
        heading,
        href,
        subheading,
        underButtonText
    } = props.content.landingPage.freeTrial;

    return (
        <TextImageSecond
            {...{
                buttonLabel,
                content,
                heading,
                href,
                subheading,
                underButtonText
            }}
            className="FreeTrialView"
        />
    );

};

export default withLanguageContext(FreeTrialView);
