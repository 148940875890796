import React from 'react';

import './styles.css';

interface Props {
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const Row: React.FC<Props> = (props) => {

    return (
        <div className='Row'>
            {props.children}
        </div>
    );

};

export default Row;
