import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { SlideType } from '../../../utils/types';
import PaginationButton from '../../atoms/PaginationButton';
import './styles.css';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

type Props = {
  slides: SlideType[];
  autoplayInterval: number;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const TextSlider: React.FC<Props> = ({ slides, autoplayInterval }: Props) => {
  const [index, setIndex] = useState(0);

  const handleChangeIndex = (index: number) => setIndex(index);

  const handleRightClick = () => {
    let slideIndex = index;
    if (index === slides.length - 1) {
      setIndex(0);
      return;
    }
    setIndex(++slideIndex);
  };

  const handleLeftClick = () => {
    let slideIndex = index;
    if (slideIndex === 0) {
      setIndex(slides.length - 1);
      return;
    }
    setIndex(--slideIndex);
  };

  const mapSlides = ({ heading, subHeading }: SlideType, index: number) => {
    return (
      <div className={'slidesWrapper'} key={index}>
        <h2>{heading}</h2>
        <h2>{subHeading}</h2>
      </div>
    );
  };

  const genSwipeableViews = ({ index, slides }: { index: number; slides: SlideType[] }) => (
    <AutoPlaySwipeableViews
      interval={autoplayInterval}
      enableMouseEvents={true}
      index={index}
      onChangeIndex={handleChangeIndex}
      animateTransitions={true}
      resistance={true}
    >
      {slides.map(mapSlides)}
    </AutoPlaySwipeableViews>
  );

  return (
    <div className='slider'>
      {genSwipeableViews({ index, slides })}
      <PaginationButton className='TextSlider-button-left' onClick={handleLeftClick} />
      <PaginationButton className='TextSlider-button-right' onClick={handleRightClick} right />
    </div>
  );
};

export default TextSlider;
