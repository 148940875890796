import React, { Component } from 'react';
import classNames from 'classnames';
import './styles.css';
import { DocBrowserLogo, NavButton } from '../../atoms';
import { NavItemGroup } from '../../molecules';
import { NavigationItem } from '../../../utils/types';


type Props = {
    navItems: NavigationItem[];
    className: string;
    opaque: boolean;
};

type State = {
    isNavOpen: boolean;
    isSmallScreen: boolean;
};

const MQ_SMALL_SCREEN = '(max-width: 1064px)';

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class Header extends Component<Props, State> {

    mql: MediaQueryList | undefined;

    state = {
        isNavOpen: false,
        isSmallScreen: window.matchMedia(MQ_SMALL_SCREEN).matches,
    };

    componentDidMount() {
        this.mql = window.matchMedia(MQ_SMALL_SCREEN);
        this.mql.addListener(this.handleMediaChange);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.state.isNavOpen !== prevState.isNavOpen) {
            if (this.state.isNavOpen) {
                this.toggleBodyOverflow(false);
            } else {
                this.toggleBodyOverflow(true);
            }
        }
    }

    handleAnchorClick = ({ target }: React.BaseSyntheticEvent) => {
        if (target.tagName === 'A') this.setState({ isNavOpen: false });
    }

    handleMediaChange = ({ matches }: MediaQueryListEvent) =>
        this.setState(({ isNavOpen }) => ({
            isNavOpen: matches ? isNavOpen : false,
            isSmallScreen: !!matches,
        }));

    handleNavClick = (): void =>
        this.setState(({ isNavOpen }) => ({
            isNavOpen: !isNavOpen,
        }));

    genClassName = (props: Props, state: State): string =>
        classNames('Header', props.className, {
            backdrop: props.opaque || state.isNavOpen,
            backdropFull: state.isNavOpen,
        });

    genDrawClassName = (state: State): string =>
        classNames('Header-mobileDraw', {
            visible: state.isNavOpen,
        });

    toggleBodyOverflow = (shouldShow: boolean): void => {
        document.body.style.overflowY = shouldShow ? '' : 'hidden';
    }

    render () {
        const { navItems = [], opaque } = this.props;
        const { isNavOpen, isSmallScreen } = this.state;

        return (
            <header
                className={this.genClassName(this.props, this.state)}
                onClick={this.handleAnchorClick}
            >
                <div className='Header-wrapper'>
                    <DocBrowserLogo className='Header-logo' white={!opaque && !isNavOpen} />
                    {isSmallScreen
                        ? <NavButton onClick={this.handleNavClick} open={isNavOpen} />
                        : <NavItemGroup className='Header-navItems' navItems={navItems} />
                    }
                </div>
                {isSmallScreen
                    ? <div className={this.genDrawClassName(this.state)}>
                        <NavItemGroup className='Header-navItems' navItems={navItems} />
                    </div>
                    : null
                }
            </header>
        );
    }
}
