import React, { Component } from 'react';
import { AppType } from '../../../utils/types';
import { genClassName } from '../../../utils/helpers';
import { Row, Column } from '../../atoms';
import './styles.css';
import { Link } from 'react-router-dom';

type Props = {
  downloadApps: AppType[];
  className: string;
  heading: string;
  href: string;
  buttonLabel: string;
  subHeaderContent: string[];
};

/**
 * Description
 *
 * @typedef {Object} Props
 *
 * @augments {Component<Props>}
 */
export default class Portfolio extends Component<Props> {

  genTextBlock = (downloadApps: AppType[]) =>
    downloadApps.map((app: AppType, idx: number) => (
      <Column key={idx}>
        <picture className={genClassName('', {tabletImage: idx < 4})}>
          {app.label ? <span className='subItemLabel'>{app.label}</span> : null }
          <img src={require(`../../../assets/images/${app.image.src}`)} alt={app.image.alt} />
        </picture>
        <div className={'infoBlock'}>
          <h2>{app.title}</h2>
          <p className={'appContent'}>{app.content}</p>
          {app.href && (
            <a href={app.href} target='_blank' rel='noopener noreferrer'>
              <img src={require('../../../assets/svg/app_store_badge.svg')} alt='App store badge' />
            </a>
          )}
          {app.link && (
            <a href={app.link} target='_blank' rel='noopener noreferrer'>
              View partner website
            </a>
          )}
        </div>
      </Column>
    ));

  render() {
    const { downloadApps, heading, className, subHeaderContent, href, buttonLabel } = this.props;

    return (
      <section className={genClassName('Portfolio', className)}>
        {heading ? <h1>{heading}</h1> : ''}
        {subHeaderContent?.length && subHeaderContent.map((sub, index) => <p key={index}>{sub}</p>)}
        <div className='Portfolio-container'>
          <Row>{this.genTextBlock(downloadApps)}</Row>
        </div>
        <Link className="Button Portfolio-Button Button-link" to={href}>{buttonLabel}</Link>
      </section>
    );
  }
}
