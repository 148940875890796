import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './styles.css';

type Props = {
  animated?: boolean;
  className: string;
  white?: boolean;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class DocBrowserLogo extends Component<Props, {}> {
  genClassName = (props: Props) =>
    classNames('DocBrowserLogo', props.className, {
      white: props.white,
    });

  render() {
    const animated = this.props.animated;
    /* eslint-disable max-len */
    return (
      <Link to='/'>
        <div className={this.genClassName(this.props)}>
          {animated ? (
            <div className='cubespinner-wrap'>
              <div className='cubespinner'>
                <div className='face1'>T</div>
                <div className='face2'>A</div>
                <div className='face3'>G</div>
                <div className='face4'>G</div>
                <div className='face5'>I</div>
                <div className='face6'>S</div>
              </div>
            </div>
          ) : null}
          <img alt='Logo' src={require('../../../assets/svg/Doc-Browser-Light-Bigger.svg')} />
        </div>
      </Link>
    );
  }
}
