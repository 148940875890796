import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import WingDoxLearnMore from '../../templates/WingDoxLearnMore';

type Props = {
    content: {
        landingPage: {
            wingDoxLearnMore: {
                buttonLabel: string;
                href: string;
            }
        }
    }
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const WingDoxLearnMoreView: React.FC<Props> = (props) => {
    const {
        buttonLabel,
        href,
    } = props.content.landingPage.wingDoxLearnMore;

    return (
        <WingDoxLearnMore
            {...{
                buttonLabel,
                href,
            }}
        />
    );

};

export default withLanguageContext(WingDoxLearnMoreView);
