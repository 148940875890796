import React from 'react';

import NewsPage from '../../templates/NewsPage';
import { withLanguageContext } from '../../../utils/LanguageContext';

type Props = {
	content: {
		newsPage: {
			heading: {
				title: string,
				subTitle: string
			}

		}
	};
};


/**
 * The FAQ section of the landing page.
 *
 * @typedef {Object} Props
 * @prop {Object}  content
 *
 * @augments {Component<Props>}
 */

const NewsPageView: React.FC<Props> = (props: Props) => {
	const { content } = props;

	return <NewsPage content={content} />;

}

export default withLanguageContext(NewsPageView);
