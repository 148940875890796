import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { VideoBoxWithText } from '../../templates';

import './styles.css';

type Props = {
    content: {
        solutionsPage: {
            ireformApp: {
                buttonLabel: string;
                content: string;
                heading: string;
                href: string;
                videoId: string;
				image: {
					src: string;
					alt: string;
				}
            }
        }
    }
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const IreformAppView: React.FC<Props> = (props: Props) => {

	const {
        buttonLabel,
        content,
        heading,
        href,
        videoId,
        image
    } = props.content.solutionsPage.ireformApp;

    return (
        <VideoBoxWithText
			heading={heading}
			content={content}
			videoId={videoId}
			image={image}
            href={href}
            buttonLabel={buttonLabel}
            className='IreformAppView'
        />
    );
};

export default withLanguageContext(IreformAppView);



