import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

type Props = {
    href: string;
    children: React.ReactChild;
    onClick?: (event: React.SyntheticEvent) => void;
};

const SUPPORTS_SMOOTH_SCROLL = typeof CSS !== 'undefined' && CSS.supports('scroll-behavior', 'smooth');

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class NavItem extends Component<Props, {}> {

    anchorRef: HTMLElement | null | undefined;

    componentDidMount () {
        if (!SUPPORTS_SMOOTH_SCROLL && this.isHashLink) this.setupSmoothScroll();
    }

    handleClick = (event: React.SyntheticEvent) => {
        if (this.anchorRef && this.anchorRef.parentElement) {
            event.preventDefault();

            /* NOTE: The following code:
             * 1: stores the current scroll position,
             * 2: sets the hash (so that the adress bar updates appropriately), and then
             * 3: scrolls to the correct position. */
            const prevScrollYPos = window.scrollY;

            const top = this.anchorRef.parentElement.offsetTop + this.anchorRef.offsetTop;

            window.location.hash = this.props.href;
            window.scrollTo(0, prevScrollYPos);
            requestAnimationFrame(() => window.scrollTo({ behavior: 'smooth', top }));
        }
    }

    isHashLink = this.props.href.includes('#');
    isSignInLink = this.props.href.includes('sign-in');

    setMatchingElement = (idWithHash: string) => {
        const id = idWithHash.substr(1);
        this.anchorRef = document.getElementById(id);
    }

    setupSmoothScroll = async () => {
        const smoothScroll = await import('smoothscroll-polyfill');
        smoothScroll.polyfill();
        this.setMatchingElement(this.props.href);
    }

    render () {
        const { children, href } = this.props;

        // For standard navigation links.
        if (!this.isHashLink && !this.isSignInLink) {
            return <Link className='NavItem' to={href}>{children}</Link>;
        }

        // For hash links.
        const props: { href: string; onClick?: (event: React.SyntheticEvent) => void; } = { href };
        if (!SUPPORTS_SMOOTH_SCROLL) props.onClick = this.handleClick;
        return <a className='NavItem' {...props}>{children}</a>;
    }

}
