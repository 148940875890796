import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { AppointmentBookingPageType } from '../../../utils/types';
import { AppointmentBooking } from '../../templates';

import './styles.css';

interface Props{
  content: {
    bookMeetingPage: AppointmentBookingPageType;
  };

};

const AppointmentBookingView: React.FC<Props> = (props: Props) => {
  const { heading, subHeading, bookOptionsHeading, emailLabel, bookingOptions, buttonLabel } =
    props.content.bookMeetingPage;



  return (
    <AppointmentBooking
      {...{ heading, subHeading, bookOptionsHeading, emailLabel, bookingOptions, buttonLabel}}
    />
  );
};

export default withLanguageContext(AppointmentBookingView);
