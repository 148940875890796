import React from 'react';
import { PaddedSection } from '../../atoms';

import './styles.css';

type Props = {
    buttonLabel: string;
    href: string;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const WingDoxLearnMore: React.FC<Props> = (props) => {

    const {
        buttonLabel,
        href
    } = props;

    return (
        <PaddedSection className='WingBoxLearnMore'>
            <a
                className='Button Button-link'
                href={href}
                rel='noopener noreferrer'
                target='_blank'
            >
                <span>{buttonLabel}</span>
            </a>
        </PaddedSection>
    );
}

export default WingDoxLearnMore;
