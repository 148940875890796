import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { LinkedInMedia, TextOnly } from '../../templates';

import './styles.css';

type Props = {
  content: {
    socialMediaPage: {
      heading: string;
      linkedInLogo: string;
      videos: string[];
    };
  };
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const LinkedInMediaView: React.FC<Props> = (props: Props) => {
  const { heading, linkedInLogo, videos } = props.content.socialMediaPage;
  const logoSrc = require(`../../../assets/images/${linkedInLogo}`);
  const linkedInLogoNode = `<img src="${logoSrc}" />`;

  return (
    <div className='LinkedInMediaView'>
      <TextOnly className={'socialMediaTextOnly'} heading={heading} content={linkedInLogoNode} />
      <LinkedInMedia {...{ heading, linkedInLogo, videos }} />
    </div>
  );
};

export default withLanguageContext(LinkedInMediaView);
