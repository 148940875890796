import React, { Component } from 'react';
import { PaddedBlock } from './components/atoms';
import { HeaderView, FooterView, AboutAndPartnersView } from './components/views';

class AboutAndPartnersPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main>
        <HeaderView opaque={true} />
        <PaddedBlock padding={80} />
        <AboutAndPartnersView />
        <FooterView />
      </main>
    );
  }
}

export default AboutAndPartnersPage;
