import React from "react";
import { withLanguageContext } from "../../../utils/LanguageContext";
import { FbPricingTable} from "../../templates";

export type FbPricingTableProps = {
  heading: string;
  subHeading: string;
  hintStd: string;
  hintWb: string;
  supportHint: string;
  table: {
    head: {
      product: string;
      ac: string;
      price: string;
    };
    subHead: string[]
    rows: {
      FlightBriefing: string;
      TotalAircrafts: string;
	  licenses: string;
      support: string;
      sum: string;
    };
  };
}

type Props = {
  content: {
    pricingPage: {
		fbPricing: {
			fbPricingTable: FbPricingTableProps;
		};
    };
  };
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const FbPricingTableView: React.FC<Props> = (props: Props) => {
  const pricingTable = props.content.pricingPage.fbPricing.fbPricingTable;
  return <FbPricingTable {...pricingTable} />;
};

export default withLanguageContext(FbPricingTableView);
