import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { ImageType } from '../../../utils/types';
import { TextImageSecond, TextOnly } from '../../templates';

import './styles.css';

type Props = {
  content: {
      aboutAndPartnersPage: {
        headerTitle: string;
        headerContent: string;
        buttonLabel: string;
        content: string;
        href: string;
        subheading: string;
        brandImages: ImageType[];
      };
  };
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const AboutAndPartnersView: React.FC<Props> = (props: Props) => {
  const { buttonLabel, content, href, subheading, headerTitle, brandImages, headerContent } =
    props.content.aboutAndPartnersPage;

  return (
    <>
      <TextOnly heading={headerTitle} content={headerContent} className={'textOnly'} />
      <TextImageSecond
        {...{
          buttonLabel,
          content,
          href,
          subheading,
          brandImages,
        }}
        className='AboutAndPartnersView'
      />
    </>
  );
};

export default withLanguageContext(AboutAndPartnersView);
