import React from 'react';
import './styles.css';
import { Error } from '../../../utils/types';

type Props = {
    validationMessage: string;
    errors: Error[];
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const FormValidationMessage: React.FC<Props> = (props: Props) => {

    const { errors, validationMessage } = props;

    return (
        <div className='FormValidationMessage' role='alert'>
            <p>{validationMessage}</p>
            <ul>
                {errors.map(({ fieldName, reasons }) => (
                    <li key={`validationItem-${fieldName}`}>
                        <strong>{fieldName}: </strong> {reasons}.
                    </li>
                ))}
            </ul>
        </div>
    );

};

export default FormValidationMessage;
