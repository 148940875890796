import React, { Component } from 'react';

import './styles.css';
import { Footer } from '../../templates';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { NavItemType, NavigationItem } from '../../../utils/types';

type Props = {
    content: {
        landingPage: {
            footer: {
                address: string;
                content: string;
            }
            navigation: {
                items: NavItemType[];
            }
        }
    }
};

type State = {
    navItems: NavigationItem[];
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
class FooterView extends Component<Props, State> {

    state = {
        navItems: this.genNavItems(this.props.content.landingPage.navigation.items),
    };

    /**
     * @argument {Object[]} items
     * @returns {Object[]}
     */
    genNavItems (items: NavItemType[]): NavigationItem[] {
        return items.map(({ href, name, subItems }) => ({
            children: name,
            href,
            subItems: subItems?.length ? subItems.map(({ href, name }) =>({
                children: name,
                href,
            })) : undefined,
        }));
    }
    render () {

        const { address, content } = this.props.content.landingPage.footer;
        const navItems = this.state.navItems;

        return (
            <Footer
                {...{
                    address,
                    content,
                    navItems,
                }}
            />
        );
    }
}

export default withLanguageContext(FooterView);
