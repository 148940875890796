import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { AppType } from '../../../utils/types';
import { Portfolio } from '../../templates';

type Props = {
    content: {
        landingPage: {
            productPortfolio: {
                heading: string,
                href: string,
                buttonLabel: string,
                subHeaderContent: string[],
                downloadApps: AppType[];
            }
        }
    }
};

/**
 * Download apps view on landing page
 *
 * @typedef {Object} Props
 *
 * @augments {Component<Props>}
 */
const PortfolioView: React.FC<Props> = (props) => {
    const {downloadApps, heading, subHeaderContent, href, buttonLabel} = props.content.landingPage.productPortfolio;

    return (
        <Portfolio
            downloadApps={downloadApps}
            heading={heading}
            href={href}
            buttonLabel={buttonLabel}
            subHeaderContent={subHeaderContent}
            className='PortFolioView'
        />
    );

};

export default withLanguageContext(PortfolioView);
