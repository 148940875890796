import React, { Component, createRef } from "react";
import { PaddedBlock } from "./components/atoms";
import {
  HeaderView,
  FooterView,
  PricingHeroView,
  PricingTableView,
  PricingOtherProducts,
  PricingInfo,
  FbPricingTableView,
} from "./components/views";

const SUPPORTS_INTERSECTION_OBSERVER = typeof IntersectionObserver !== 'undefined';
const THRESHOLD = 0.95;

class BookMeetingPage extends Component {
  io: IntersectionObserver | undefined;

  state = {
    showOpaqueHeader: false,
  };

  heroRef = createRef<HTMLElement>();

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setupIntersectionObserver();
  }

  componentWillUnmount() {
    if (this.io) this.io.disconnect();
  }

  handleThresholdMatch(entries: IntersectionObserverEntry[]) {
    const [{ intersectionRatio }] = entries;
    this.setState({
      showOpaqueHeader: intersectionRatio <= THRESHOLD,
    });
  }

  setupIntersectionObserver = async () => {
    if (!SUPPORTS_INTERSECTION_OBSERVER) await import("intersection-observer");
    this.io = new IntersectionObserver(this.handleThresholdMatch.bind(this), {
      threshold: [THRESHOLD],
    });
    if (this.heroRef.current) this.io.observe(this.heroRef.current);
  };

  render() {
    return (
      <main>
        <HeaderView opaque={this.state.showOpaqueHeader} />
        <PricingHeroView forwardedRef={this.heroRef} />
        <PricingTableView />
        <FbPricingTableView />
        <PricingOtherProducts />
        <PaddedBlock padding={80} />
        <PricingInfo />
        <PaddedBlock padding={80} />
        <FooterView />
      </main>
    );
  }
}

export default BookMeetingPage;
