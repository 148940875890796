import React from 'react';

import './styles.css';
import { genClassName } from '../../../utils/helpers';

interface Props {
    forwardedRef?: React.Ref<HTMLElement>;
    className: string;
    style?: object;
    children: React.ReactNode;
};

/**
 * A simple section with variable padding.
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const PaddedSection: React.FC<Props> = (props) => {
    const { children, forwardedRef, className, style } = props;
    return (
        <section
            ref={forwardedRef}
            className={ genClassName('PaddedSection', className) }
            style={style}
        >
            {children}
        </section>
    );

};

export default PaddedSection;
