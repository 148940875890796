import React from 'react';
import { QuoteType } from '../../../utils/types';
import { QuotesSection } from '../../templates';
import { withLanguageContext } from '../../../utils/LanguageContext';



type Props = {
    content: {
        landingPage: {
            quotes: {
                heading: string;
                quotes: QuoteType[];
            }
        }
    }
};

/**
 * Quotes view on landing page
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const QuotesView: React.FC<Props> = (props: Props) => {
    const {
        heading,
        quotes,
    } = props.content.landingPage.quotes;

    return (
        <QuotesSection {...{
            heading,
            quotes,
        }} />
    );

};

export default withLanguageContext(QuotesView);
