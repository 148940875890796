import React, { Component } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { PaddedSection, PaginationButton, SlidePagination } from '../../atoms';
import { QuoteType } from '../../../utils/types';
import ContentConverter from '../../../utils/contentConverter';

import './styles.css';

type Props = {
	heading: string;
    quotes: QuoteType[];
};

type State = {
    index: number;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class QuotesSection extends Component<Props, State> {

    state = {
        index: 0,
    };

    genSwipeableViews = ({ index, quotes }: {index: number; quotes: QuoteType[] }) => (
        <SwipeableViews
            enableMouseEvents={true}
            index={index}
            onChangeIndex={this.handleChangeIndex}
            resistance={true}
        >
            {quotes.map(this.mapQuotes)}
        </SwipeableViews>
    );

    mapQuotes = ({ quote, author, logo }: QuoteType, index: number) => {
        return (
            <blockquote key={index} className='QuotesSection-quote'>
                <span dangerouslySetInnerHTML={this.convertString(quote)} />
                <footer>
                    <cite>{author}</cite>
                    {logo && <img src={require(`../../../assets/svg/${logo.src}`)} alt={`${logo.alt}`} />}
                </footer>
            </blockquote>
        );
    };

    convertString = (string: string) => new ContentConverter(string).nlToBr().getReactHtml();

    handleChangeIndex = (index: number) => this.setState({ index });

    handleRightClick = () => {
        this.setState(({ index }) => {
            if (index === this.props.quotes.length - 1) return { index: 0 };
            return { index: ++index };
        });
    }

    handleLeftClick = () => {
        this.setState(({ index }) => {
            if (index === 0) return { index: this.props.quotes.length - 1 };
            return { index: --index };
        });
    }

    render () {
        const index = this.state.index;
        const quotes = this.props.quotes;
        const heading = this.props.heading;

        return (
            <PaddedSection className='QuotesSection'>
                <h1>{heading}</h1>
                <div className={'slidePaginationWrap'}>
                    <SlidePagination
                        count={quotes.length}
                        index={index}
                        onChange={this.handleChangeIndex}
                    />
                </div>
                {this.genSwipeableViews({ index, quotes })}
                <PaginationButton
                    className='QuotesSection-button-left'
                    onClick={this.handleLeftClick}
                />
                <PaginationButton
                    className='QuotesSection-button-right'
                    onClick={this.handleRightClick}
                    right={true}
                />
            </PaddedSection>
        );
    }

}
