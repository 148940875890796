import React, { Component } from 'react';
import { PaddedSection } from '../../atoms';
import brochure from '../../../assets/images/brochure.png';
import './styles.css';

type MyProps = {
	buttonLabel: string;
	fileName: string;
	heading: string;
};

/**
 * Description
 *
 * @typedef {Object} Props
 *
 * @augments {Component<Props>}
 */
export default class LearnMore extends Component<MyProps, {}> {

	render () {
		const { buttonLabel, fileName, heading } = this.props;

		const href = require(`../../../assets/files/${fileName}`);

		const arrowDown = require(`../../../assets/svg/icon-arrow-down.svg`);
		return (
			<PaddedSection className='DownloadBrochure'>
				<div className='LearnMore'>
					<div className='LearnMore__brochure'>
						<picture>
							<img
								src={brochure}
								alt='brochure'
								className='LearnMore__image'
							/>
						</picture>
					</div>
					<div className='LearnMore__content'>
						<div>
						<h1>{heading}</h1>
						<div>
							<a
								className='Button Button-link'
								href={href}
								rel='noopener noreferrer'
								target='_blank'
							>
								<img src={arrowDown} alt='Arrow Down' />
								<span>{buttonLabel}</span>
							</a>
							</div>
						</div>
					</div>
				</div>
			 </PaddedSection>
		);
	}

}
