import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

type Props = {
	heading: string;
	content: string;
	contentEnd: string;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const OurPricing: React.FC<Props> = (props: Props) => {
	const {heading, content, contentEnd} = props;
	return (
		<div className='OurPricing text-center'>
			<div className="contentWrap">
				<div className="title">{heading}</div>
				<div className="content">
					<p>{content}</p>
					<p>We give you full transparency with our interactive <Link to={"/pricing"}>cost calculator</Link>, so that you have everything you need to know right here.</p>
				</div>
				<div className="contentEnd">
					{contentEnd}
				</div>
			</div>
		</div>
	);

};

export default OurPricing;
