import React, { Component } from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import TermsDialog from '../../molecules/TermsDialog';
import { History } from 'history';


const TYPES = ['cookies', 'privacy', 'terms'];

type Terms = {
    [key: string]: string;
};

type Props = {
    location: Location;
    content: {
        terms: Terms
    };
    history: History;
};


/**
 * Description
 *
 * @typedef {Object} Props
 *
 * @augments {Component<Props>}
 */
class TermsDialogsView extends Component<Props, {}> {

    getType = (pathname: string) => TYPES.find((type) => pathname.includes(type));

    handleClose = (type: string, pathname: string) => () => {
        this.props.history.push(pathname.replace(`/${type}`, ''));
    }

    render () {
        const { pathname } = this.props.location;
        const type = this.getType(pathname);
        let content = '';
        let handleClose = () => {};
        if (type) {
            content = this.props.content.terms[type];
            handleClose = this.handleClose(type, pathname);
        }

        return (
            <TermsDialog visible={true} onClose={handleClose}>
                {content}
            </TermsDialog>
        );
    }
}

export default withLanguageContext(TermsDialogsView);
