import React, { Component } from 'react';
import { Header } from '../../organisms';
import content from '../../../constants/content';
import { NavItemType, NavigationItem } from '../../../utils/types';
import './styles.css';

type Props = {
    opaque: boolean;
};

type State = {
    content: {
        landingPage: {
            navigation: {
                items: NavItemType[];
            }
        }
    };
    navItems: NavigationItem[];
};


/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
class HeaderView extends Component<Props, State> {

    state = {
        content: content.en,
        navItems: [],
    };

    componentDidMount() {
        this.setState({navItems: this.genNavItems(this.state.content.landingPage.navigation.items)})
    }

    genNavItems(items: NavItemType[]): NavigationItem[] {
        return items.map(({ href, name, subItems, displayAsButton, label }) => ({
            children: name,
            href,
            label: label || undefined,
            subItems: subItems?.length ? subItems.map(({ href, name }) =>({
                children: name,
                href,
            })) : undefined,
            displayAsButton
        }));
    }

    render () {
        return (
            <Header
                className="HeaderView"
                navItems={this.state.navItems}
                opaque={this.props.opaque}
            />
        );
    }

}

export default HeaderView;
