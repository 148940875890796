import React from 'react';
import { TextOnly } from '../../templates';
import { withLanguageContext } from '../../../utils/LanguageContext';
import './styles.css';

interface Props {
    content: {
        landingPage: {
            whatWeDo: {
                content: string;
                heading: string;
                subheading: string;
            }
        }
    };
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const WhatWeDoView: React.FC<Props> = (props: Props) => {

    const {
        content,
        heading,
        subheading,
    } = props.content.landingPage.whatWeDo;

    return (
        <TextOnly
            {...{
                content,
                heading,
                subheading,
            }}
        />
    );

};

export default withLanguageContext(WhatWeDoView);
