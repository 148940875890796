import { useEffect, useState } from "react";

const MQ_MOBILE_SCREEN = '(max-width: 720px)';

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(window.matchMedia(MQ_MOBILE_SCREEN).matches);

    const mql: MediaQueryList =  window.matchMedia(MQ_MOBILE_SCREEN);

    const handleMediaChange = ({ matches }: MediaQueryListEvent) => setIsMobile(!!matches);

    useEffect(() => {
        mql.addListener(handleMediaChange);

        return () => mql.removeListener(handleMediaChange);
    }, [mql]);

    return isMobile;
};
