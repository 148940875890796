import { API } from '../constants';
import {
  AppointmentRequestType,
  FormDataType,
  FromParamType,
  LinkedInPostCategoryType,
  LinkedInPostType,
  NewsCategoryType,
  NewsPostType,
  StrapiResponseType,
} from '../utils/types';

enum StrapiRoutes{
  NEWS_POSTS = 'db-news-posts',
  NEWS_CATEGORIES = 'db-news-categories',
  LINKEDIN_POSTS = 'db-linkedin-posts',
  LINKEDIN_POST_CATEGORIES = 'db-linkedin-post-categories'
}

const TYPE_JSON = 'application/json';
const CONTENT_TYPE = 'Content-Type';

const OPTIONS: RequestInit | undefined = {
  headers: { [CONTENT_TYPE]: TYPE_JSON },
  method: 'POST',
};

const GETOPTIONS: RequestInit | undefined = {
  headers: { [CONTENT_TYPE]: TYPE_JSON },
  method: 'GET',
};

// @ts-ignore
const isJson = (response: Response): boolean => response.headers.get(CONTENT_TYPE).includes(TYPE_JSON);

async function postRequest<T>({ data, path = '' }: FromParamType<T>, getFullError = false) {
  try {
    const response = await fetch(path, {
      body: JSON.stringify(data),
      ...OPTIONS,
    });

    const success = response.ok;
    if (!success) {
      if (getFullError) {
        const res = await response.json();
        throw res;
      }
      throw Error(response.statusText);
    }
    if (isJson(response)) {
      const json = await response.json();
      return { json, success };
    } else if (success) {
      return { success };
    } else {
      throw Error(response.statusText);
    }
  } catch (error) {
    if (getFullError) {
      throw error;
    }
    throw Error(error);
  }
}

async function getRequest<T>({ path = '' }, getFullError = false): Promise<{ success: boolean; json?: T }> {
  try {
    const response = await fetch(path, {
      ...GETOPTIONS,
    });

    const success = response.ok;
    if (!success) {
      if (getFullError) {
        const res = await response.json();
        throw res;
      }
      throw Error(response.statusText);
    }
    if (isJson(response)) {
      const json = await response.json();
      return { json, success };
    } else if (success) {
      return { success };
    } else {
      throw Error(response.statusText);
    }
  } catch (error) {
    if (getFullError) {
      throw error;
    }
    throw Error(error);
  }
}

export const contactRequest = (data: FormDataType) =>
  postRequest<FormDataType>({ data, path: `${API.basePath}landing/contact` });
export const requestMeeting = (data: AppointmentRequestType) =>
  postRequest<AppointmentRequestType>({ data, path: `${API.basePath}landing/appointment-booking` }, true);

//STRAPI related
export const getNewsPosts = (params = '') =>
  getRequest<StrapiResponseType<NewsPostType>>({ path: `${API.strapiPath}${StrapiRoutes.NEWS_POSTS}${params}` }, true);
export const getNewsPostsCategories = (params = '') =>
  getRequest<StrapiResponseType<NewsCategoryType>>({ path: `${API.strapiPath}${StrapiRoutes.NEWS_CATEGORIES}${params}` }, true);
export const getLinkedInPosts = (params = '') =>
  getRequest<StrapiResponseType<LinkedInPostType>>({ path: `${API.strapiPath}${StrapiRoutes.LINKEDIN_POSTS}${params}` }, true);
export const getLinkedInPostsCategories = (params: string = '') =>
  getRequest<StrapiResponseType<LinkedInPostCategoryType>>(
    { path: `${API.strapiPath}${StrapiRoutes.LINKEDIN_POST_CATEGORIES}${params}` },
    true
  );
