import React, { Component } from 'react';
import { DocBrowserLogo } from '../../atoms';
import { NavItemGroup } from '../../molecules';
import { NavigationItem } from '../../../utils/types';
import ContentConverter from '../../../utils/contentConverter';

import './styles.css';

type Props = {
    content: string;
    address: string;
    navItems: NavigationItem[];
};

type ScriptProps = {
    [key: string]: string;
}

/**
 * Description
 *
 * @typedef {Object} Props
 *
 * @augments {Component<Props>}
 */
export default class Footer extends Component<Props, {}> {

    componentDidMount() {
        const linkedinPlatform: ScriptProps = {
            src: 'https://platform.linkedin.com/in.js',
            type: 'text/javascript',
            content: 'lang: en_US',
        };
        const lonkedinCompany: ScriptProps = {
            type: 'IN/FollowCompany',
            'data-id': '30732676',
            'data-counter': 'bottom',
        };

       const footerAddrress = document.querySelector('.Footer-address');

       if (footerAddrress) {
        footerAddrress.append(this.createScript(linkedinPlatform));
        footerAddrress.append(this.createScript(lonkedinCompany));
       }
    }

    createScript(data: ScriptProps): HTMLScriptElement {
        const script = document.createElement('script');

        for (let prop in data) {
            if (prop !== 'content') {
                script.setAttribute(prop, data[prop]);
            } else {
                script.innerHTML = data[prop];
            }
        }
        return script;
    }

    genContent = (content: string): string => content.replace('{year}', new Date().getFullYear().toString());

    getAddress = (address: string) => new ContentConverter(address).nlToBr().convertLinks().getReactHtml();

    render () {
        const navItems = this.props.navItems;
        const {
            content,
            address,
        } = this.props;

        return (
            <div className='Footer'>
                <DocBrowserLogo animated={false} className='Footer-logo' />
                <div className='Footer-nav'>
                    <NavItemGroup className='Footer-navItems' navItems={navItems} />
                </div>
                <address className='Footer-address' dangerouslySetInnerHTML={this.getAddress(address)} />
                <p className='Footer-copy'>{this.genContent(content)}</p>
            </div>
        );
    }

}
