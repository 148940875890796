import React from 'react';
import { ImageType } from '../../../utils/types';

import './styles.css';

type Props = {
  images: ImageType[];
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const BrandIcons: React.FC<Props> = ({ images }: Props) => {
  const genImages = (images: ImageType[]) =>
    images.map((image: ImageType, key: number) => {
      const imageUrl = require(`../../../assets/svg/${image.src}`);
      return (
        <div key={key}>
          <img src={imageUrl} alt={`${image.alt}`} />
        </div>
      );
    });

  return <div className='BrandIcons'>{genImages(images)}</div>;
};

export default BrandIcons;
