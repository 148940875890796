import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContentConverter from '../../../utils/contentConverter';
import { genClassName } from '../../../utils/helpers';
import { PaddedSection } from '../../atoms';

import './styles.css';

type Props = {
    className: string;
    heading: string;
	content: string;
	buttonLabel: string;
	href: string;
    forwardedRef: React.Ref<HTMLElement>;
};

type MyState = {
	example: string;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class PricingHero extends Component<Props, MyState> {

	convertText = (heading: string) => new ContentConverter(heading).nlToBr().getReactHtml();

	render () {
		const { className, forwardedRef, heading, content, buttonLabel, href } = this.props;

		return (
			<PaddedSection
                className={ genClassName('PricingHero', className) }
                forwardedRef={forwardedRef}
            >
				<div className="contentInner">
					<h1>{heading}</h1>
					<div className='content' dangerouslySetInnerHTML={this.convertText(content)} />
					{href && buttonLabel ? (
						<Link
							className='Button PricingHero-button'
							to={href}
						>
							<span>{buttonLabel}</span>
						</Link>
					) : null}
				</div>
			</PaddedSection>
		);
	}

}
