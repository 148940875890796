import React, { Component } from 'react';

import './styles.css';
import ContentConverter from '../../../utils/contentConverter';
import { genClassName } from '../../../utils/helpers';
import { PaddedSection } from '../../atoms';

type Props = {
    content: string;
    heading: string;
    subheading?: string;
    className?: string;
};

/**
 * A template for text-only sections of the landing page.
 *
 * @typedef {Object} Props
 * @prop {String} content
 * @prop {String} heading
 * @prop {String} [className]
 * @prop {String} [subheading]
 *
 * @augments {Component<Props>}
 */
export default class TextOnly extends Component<Props, {}> {

    getContent = (content: string) => new ContentConverter(content).nlToBr().getReactHtml();

    render () {
        const { content, heading, subheading, className } = this.props;

        return (
            <PaddedSection className={ genClassName('TextOnly', className ? className : '' ) }>
                {subheading ? <h2>{subheading}</h2> : null }
                <h1>{heading}</h1>
                <p dangerouslySetInnerHTML={this.getContent(content)} />
            </PaddedSection>
        );
    }

}
