import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { TextOnly } from '../../templates';

import './styles.css';

type Props = {
	content: {
	  landingPage: {
		didYouKnow: {
		  heading: string;
		  subHeading: string;
		};
	  };
	};
  };

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const DidYouKnowView: React.FC<Props> = (props: Props) => {

	const {heading, subHeading} = props.content.landingPage.didYouKnow;

	return <TextOnly className='textOnlyStyle' heading={heading} content={subHeading} />

};

export default withLanguageContext(DidYouKnowView);
