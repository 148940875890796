import React from 'react';

import './styles.css';
import { TextOnly } from '../../templates';
import { withLanguageContext } from '../../../utils/LanguageContext';

type Props = {
    content: {
        landingPage: {
            getInTouch: {
                content: string;
                heading: string;
            }
        }
    }
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const GetInTouchView: React.FC<Props> = (props) => {

    const {
        content,
        heading,
    } = props.content.landingPage.getInTouch;

    return (
        <TextOnly className='GetInTouch'
            {...{
                content,
                heading,
            }}
        />
    );

};

export default withLanguageContext(GetInTouchView);
