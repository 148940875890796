import React, { Component, Fragment } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { ContentRevison, ContentRevisonStep } from '../../../utils/types';
import { PaddedSection, SlidePagination } from '../../atoms';
import { genClassName, chunkInefficient } from '../../../utils/helpers';

import './styles.css';
import ContentConverter from '../../../utils/contentConverter';

type Props = {
    contentRevisionCycle: ContentRevison;
    className: string;
    forwardedRef?: React.Ref<HTMLElement>;
};

type State = {
    isSmallScreen: boolean;
    isTabletScreen: boolean;
    index: number;
};

const MQ_TABLET_SCREEN = '(max-width: 1024px)';
const MQ_SMALL_SCREEN = '(max-width: 720px)';

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class ContentRevisionCycle extends Component<Props, State> {

    mql: MediaQueryList | undefined;
    mqlt: MediaQueryList | undefined;

    state = {
        isSmallScreen: window.matchMedia(MQ_SMALL_SCREEN).matches,
        isTabletScreen: window.matchMedia(MQ_TABLET_SCREEN).matches,
        index: 0,
    };

    colors: string[] = ['#2b385d', '#1f507d', '#106ea5', '#0090D3', '#29adeb', '#4cc6ff'];

    componentDidMount() {
        this.mql = window.matchMedia(MQ_SMALL_SCREEN);
        this.mqlt = window.matchMedia(MQ_TABLET_SCREEN);
        this.mql.addEventListener("change", this.handleMediaChange);
        this.mqlt.addEventListener("change", this.handleMediaTabletChange);
    }

    componentWillUnmount(){
        this.mql?.removeEventListener("change", this.handleMediaChange);
        this.mqlt?.removeEventListener("change", this.handleMediaTabletChange);
    }

    handleMediaChange = ({ matches }: MediaQueryListEvent) =>
        this.setState(() => ({
            isSmallScreen: !!matches,
        }));

    handleMediaTabletChange = ({ matches }: MediaQueryListEvent) =>
        this.setState(() => ({
            isTabletScreen: !!matches,
        }));

    handleChangeIndex = (index: number) => this.setState({ index });

    genChunkSteps = (steps: ContentRevisonStep[]) => {
        const stepsChunk = chunkInefficient(3, steps);
        return stepsChunk.map((stepsChunk: ContentRevisonStep[], idx: number) => <div key={idx} className="ContentRevisionCycle-step-column">
            {stepsChunk.map((step: ContentRevisonStep, stepIdx: number) => this.genStep(step, stepIdx, idx))}
        </div>)
    }

    genTabletSteps = (steps: ContentRevisonStep[]) =>
        <div
            className="ContentRevisionCycle-steps"
        >
            {steps.map((step: ContentRevisonStep, stepIdx: number) => this.genStep(step, stepIdx, 0))}
        </div>

    genSwipeableSteps = (steps: ContentRevisonStep[]) =>
        <Fragment>
            <SwipeableViews
                className="ContentRevisionCycle-steps"
                enableMouseEvents={true}
                index={this.state.index}
                onChangeIndex={this.handleChangeIndex}
                resistance={true}
            >
                {steps.map((step: ContentRevisonStep, stepIdx: number) => this.genStep(step, stepIdx, 0))}
            </SwipeableViews>
            <SlidePagination
                count={steps.length}
                index={this.state.index}
                onChange={this.handleChangeIndex}
            />
        </Fragment>

    genStep = (step: ContentRevisonStep, stepIdx: number, stepChunkIdx: number) => {
        const number = this.getNumber(stepIdx, stepChunkIdx)
        const color = { color: this.colors[number-1] };
        return <div key={stepIdx} className="ContentRevisionCycle-step">
            <div className="ContentRevisionCycle-step-number" style={color}>{`0${number}`}</div>
            <div className="ContentRevisionCycle-step-inner">
                <h3 style={color} dangerouslySetInnerHTML={this.convertHeading(step.heading)} />
                <h5>{this.props.contentRevisionCycle.peopleLabel}</h5>
                <h4>{step.people}</h4>
                <h5>{this.props.contentRevisionCycle.productLabel}</h5>
                <h4>{step.product}</h4>
            </div>
        </div>
    }

    convertHeading = (heading: string) => new ContentConverter(heading).nlToBr().getReactHtml();

    getNumber = (stepIdx: number, stepChunkIdx: number) => stepChunkIdx === 0 ? stepIdx+1 : stepIdx+4;

    generateCycleRound = () => [1,2,3,4,5,6].map((el: number) => 
        <div
            key={el}
            style={{
                transform: `rotate(${ (el - 1) * 60 }deg)`,
            }}
            className={`cycleRound cycleRound-${el-1}`}
        >
            <span
                style={{
                    transform: `rotate(-${ (el - 1) * 60 }deg)`
                }}
            >{`0${el}`}</span>
            <svg fill={this.colors[el-1]} xmlns="http://www.w3.org/2000/svg" width="176" height="114" viewBox="0 0 176 114">
                <path fillRule="evenodd" d="M63.034 113.65c19.839-12.403 44.887-15.828 68.387-7.267l44.129-37.904-7.62-57.025C109.899-10.346 47.53.005.362 33.742l51.923 21.25 10.749 58.659"/>
            </svg>
        </div>);

    render () {
        const {
            heading,
            content,
            steps,
            roundText,
        } = this.props.contentRevisionCycle;

        return (
            <PaddedSection forwardedRef={this.props.forwardedRef} className={genClassName('ContentRevisionCycle', this.props.className)}>
                <h1>{heading}</h1>
                <p>{content}</p>
                <div className="ContentRevisionCycle-steps-wrap">
                    <div className="ContentRevisionCycle-round">
                        <div className="ContentRevisionCycle-round-inner">
                            {this.generateCycleRound()}
                            <div className="ContentRevisionCycle-content">
                                {roundText.map((el, idx) => <span
                                    key={idx}
                                    style={{
                                        animationDelay: `${0.9+(0.5*idx)}s`
                                    }}
                                    >{el}</span>)}
                            </div>
                        </div>
                    </div>
                    {!this.state.isTabletScreen && !this.state.isSmallScreen ? this.genChunkSteps(steps) : null}
                    {this.state.isTabletScreen && !this.state.isSmallScreen ? this.genTabletSteps(steps) : null}
                    {this.state.isTabletScreen && this.state.isSmallScreen ? this.genSwipeableSteps(steps) : null}
                </div>
            </PaddedSection>
        );
    }

}
