import React from "react";
import { withLanguageContext } from "../../../utils/LanguageContext";
import { OurPricing } from "../../templates";

type Props = {
  content: {
    landingPage: {
      ourPricing: {
        heading: string;
        content: string;
        contentEnd: string;
      };
    };
  };
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const OurPricingView: React.FC<Props> = (props: Props) => {
  const { heading, content, contentEnd } = props.content.landingPage.ourPricing;
  return (
    <OurPricing {...{ heading, content, contentEnd }} />
  );
};

export default withLanguageContext(OurPricingView);
