import React from 'react';

import './styles.css';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { VideoSection } from '../../templates';

type Props = {
    content: {
        landingPage: {
            aviationVideoView: {
                heading: string;
                content: string;
                heading1: string;
                heading2: string;
                videoId: string;
            }
        }
    }
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const AviationVideoView: React.FC<Props> = (props: Props) => {

    const {
        heading,
        content,
        heading1,
        heading2,
        videoId,
    } = props.content.landingPage.aviationVideoView;

    return (
        <VideoSection
            {...{
                heading,
                content,
                heading1,
                heading2,
                videoId,
            }}
            className="AviationVideoView"
        />
    );

};

export default withLanguageContext(AviationVideoView);
