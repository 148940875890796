import React, { Component } from 'react';

import './styles.css';

type Props = {
    onClick: (event: React.SyntheticEvent) => void;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class PlayButton extends Component<Props, {}> {

    handleClick = (event: React.SyntheticEvent) => this.props.onClick && this.props.onClick(event);

    render () {
        return (
            <button
                className='PlayButton'
                onClick={this.handleClick}
                type='button'
            >
                <div className='PlayButton-arrow' />
            </button>
        );
    }

}
