import React, { Component } from 'react';
import {
	NewsPageView,
	HeaderView,
	FooterView,
	ContactUsView,
    GetInTouchView
} from './components/views';

class NewsPage extends Component {
	componentDidMount () {
		window.scrollTo(0, 0);
	}

	render () {
		return (
			<main>
				<HeaderView opaque={true} />
				<NewsPageView />
				<GetInTouchView />
				<ContactUsView />
				<FooterView />
			</main>
		);
	}
}

export default NewsPage;
