import React, { Component } from 'react';
import { PaddedSection } from '../../atoms';
import { genClassName } from '../../../utils/helpers';
import { OPTIMIZED_RESIZE } from '../../../utils/optimisedResize';

import './styles.css';
import TextSlider from '../../molecules/TextSlider';
import { SlideType } from '../../../utils/types';

const UA = navigator.userAgent.toLowerCase();

type Props = {
    className: string;
    forwardedRef?: React.Ref<HTMLElement>;
    slides: SlideType[];
};

type State = {
    minHeight: number;
    prevWidth: number;
    isSmallScreen: boolean;
};

const MQ_SMALL_SCREEN = '(max-width: 1024px)';

/**
 * A template for the hero section of the landing page.
 *
 * @typedef {Object} Props
 * @typedef {Object} State
 *
 * @augments {Component<Props>}
 */
export default class Hero extends Component<Props, State> {

    mql: MediaQueryList | undefined;

    isMobileChrome: boolean = (/chrome/.test(UA) && /android/.test(UA)) || /crios/.test(UA);

    state = {
        minHeight: Math.trunc(window.innerHeight),
        prevWidth: window.innerWidth,
        isSmallScreen: window.matchMedia(MQ_SMALL_SCREEN).matches,
    }

    componentDidMount() {
        window.addEventListener(OPTIMIZED_RESIZE, this.setInnerHeight);
        this.mql = window.matchMedia(MQ_SMALL_SCREEN);
        this.mql.addListener(this.handleMediaChange);
    }

    componentWillUnmount() {
        window.removeEventListener(OPTIMIZED_RESIZE, this.setInnerHeight);
    }

    handleMediaChange = ({ matches }: MediaQueryListEvent) =>
        this.setState({
            isSmallScreen: !!matches,
        });


    setInnerHeight = () => {
        // Don't update the height when the address bar shows/hides on Chrome.
        if (this.isMobileChrome && this.state.prevWidth === window.innerWidth) return;

        this.setState({
            minHeight: Math.trunc(window.innerHeight),
            prevWidth: window.innerWidth,
        });
    }

    getRandomArbitrary = (min: number, max: number) => {
        return Math.random() * (max - min) + min;
    }

    render () {
        const { forwardedRef, className, slides } = this.props;
        return (
            <PaddedSection
                className={ genClassName('Hero', className) }
                forwardedRef={forwardedRef}
                style={{ minHeight: window.innerHeight }}
            >
                <TextSlider slides={slides} autoplayInterval={6000}/>
            </PaddedSection>
        );
    }

}
