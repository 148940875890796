import React, { Component } from 'react';
import content from '../../../constants/content';
import { PricingHero } from '../../templates';

import './styles.css';

type Props = {
    forwardedRef: React.Ref<HTMLElement>;
};

type State = {
    content: {
        pricingPage: {
            hero: {
                heading: string;
				content: string;
				buttonLabel: string;
				href: string;
            }
        }
    };
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class PricingHeroView extends Component<Props, State> {

	state = {
        content: content.en
    }

	render () {
		return (
			<PricingHero
				className='PricingHeroView'
				forwardedRef={this.props.forwardedRef}
				{...this.state.content.pricingPage.hero}
			/>
		);
	}

}
