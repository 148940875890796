import React from 'react';

import './styles.css';
import { PaddedSection } from '../../atoms';

type Props = {
    children: React.ReactNode;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const ContactUs: React.FC<Props> = (props) => {
    return (
        <PaddedSection className='ContactUs'>
            <div>
                {props.children}
            </div>
        </PaddedSection>
    );
};

export default ContactUs;
