import React, { Component } from 'react';
import content from '../../../constants/content';
import { Table } from '../../templates';
import { TableRow } from '../../templates/Table/Table';

import './styles.css';

type State = {
	content: {
        pricingPage: {
            otherProducts: {
                heading: string;
				rows: TableRow[]
            }
        }
    };
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class PricingOtherProducts extends Component<{}, State> {

	state = {
        content: content.en
    }

	render () {
		return (
			<div className='PricingOtherProducts'>
				<Table
					{...this.state.content.pricingPage.otherProducts}
				/>
			</div>
		);
	}

}
