import React, { useEffect, useRef, useState } from 'react';
import { getDisabledCalendarDates, genClassName, setTwoDaysInAdvanceDate } from '../../../utils/helpers';
import { DivAsButton, CheckBox, Input, PaddedSection } from '../../atoms';
import 'react-calendar/dist/Calendar.css';
import Calendar, { CalendarTileProperties } from 'react-calendar';
import TextOnly from '../TextOnly';

import './styles.css';
import TimePicker from '../../organisms/TimePicker';
import { requestMeeting } from '../../../sevices/DOCBrowserApi';
import { FormDialog } from '../../molecules';
import { AppointmentRequestError } from '../../../utils/types';
import { timePickerHours } from '../../../utils/timePickerHours';

type FormErrorObj = {
  email: string;
  time: string;
};

type Props = {
  heading: string;
  subHeading: string;
  emailLabel: string;
  bookOptionsHeading: string;
  bookingOptions: string[];
  buttonLabel: string;
  className?: string;
  modalView?: boolean;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const AppointmentBooking: React.FC<Props> = (props: Props) => {
  const [email, setEmail] = useState<string>('');
  const [appointmentDate, setAppointmentDate] = useState<Date>(setTwoDaysInAdvanceDate());
  const [time, setTime] = useState<string>(timePickerHours[0]);
  const [CESTTime, setCESTTime] = useState<string>();
  const [currentBookingOptions, setCurrentBookingOptions] = useState<string[]>([]);
  const [formErrorObj, setFormErrorObj] = useState<FormErrorObj>({ email: '', time: '' });
  const [appointmentSubmitted, setAppointmentSubmitted] = useState<boolean>(false);
  const [genericSubmitError, setGenericSubmitError] = useState<unknown>();

  const { className, heading, subHeading, bookOptionsHeading, buttonLabel } = props;

  const invisibleButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleInputChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    setEmail(target.value);
    if (target.value && formErrorObj['email']) {
      setFormErrorObj({ ...formErrorObj, email: '' });
    }
  };

  const handleTimeChange = (value: string) => {
    setTime(value);
    if (value && formErrorObj['time']) {
      setFormErrorObj({ ...formErrorObj, time: '' });
    }
  };

  const resetBookingData = () => {
    setEmail('');
    setCurrentBookingOptions([]);
    setTime(timePickerHours[0]);
    setAppointmentDate(setTwoDaysInAdvanceDate())
  }

  const closeAppointmentSuccessDialog = () => {
    if (genericSubmitError) {
      setGenericSubmitError(false);
    }

    if (appointmentSubmitted) {
      setAppointmentSubmitted(false);
    }
  };

  const highlightToDayDate = ({date}: CalendarTileProperties) => {
    const currentDate = new Date();
    return (date.getDate() === currentDate.getDate()) &&
      (date.getMonth() === currentDate.getMonth()) &&
      (date.getFullYear() === currentDate.getFullYear())
      ?
      'todayClassName'
      : '';
  }

  const onBookingOptionChange = (value: boolean, option: string) => {
    if (value) {
      setCurrentBookingOptions([...currentBookingOptions, option]);
      return;
    }
    setCurrentBookingOptions(currentBookingOptions.filter((currentOption) => currentOption !== option));
  };

  const disableTile = ({ date }: CalendarTileProperties) =>
    date.getDay() === 6 || date.getDay() === 0 || getDisabledCalendarDates(date);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (Object.values(formErrorObj).some((value) => !!value)) return;

    try {
      await requestMeeting({ email, date: appointmentDate.toString(), time, options: currentBookingOptions });
      setAppointmentSubmitted(true);
      resetBookingData();
    } catch (error) {
      if (error?.errors?.length) {
        const responseErrors: AppointmentRequestError[] = error.errors;
        const errors = responseErrors.reduce(
          (acc: unknown, error) => error?.title && error?.detail && { ...(acc as {}), [error.title]: error.detail },
          {}
        ) as FormErrorObj;
        setFormErrorObj(errors);
        return;
      }
      setGenericSubmitError(error);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const newTime = new Date().toLocaleString('de-DE', { timeZone: 'Europe/Berlin' }).split(',')[1];
      setCESTTime(newTime);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const renderFormContent = ({ emailLabel, bookingOptions }: Props): React.ReactNode => (
    <>
      <Input
        onInput={handleInputChange}
        autoComplete='off'
        label={emailLabel}
        required
        name='email'
        type='email'
        value={email}
        errorMessage={formErrorObj?.email}
      />
      <p className={'bookOptionsHeading'}>{bookOptionsHeading}</p>
      <ul>
        {bookingOptions.map((option, index) => (
          <li key={index} className={'optionListItem'}>
            <label>
              <CheckBox
                checked={currentBookingOptions.includes(option)}
                onChange={(value) => onBookingOptionChange(value, option)}
              />
              <span className='labelText'>{option}</span>
            </label>
          </li>
        ))}
      </ul>
      <button ref={invisibleButtonRef} className='invisibleButton' />
      <DivAsButton onClick={() => invisibleButtonRef?.current?.click()} className='Booking-Button'>
        {buttonLabel}
      </DivAsButton>
    </>
  );

  const formSubmitHeading = genericSubmitError ? 'Some Error occurred' : 'Request for Appointment submitted';
  const formSubmitContent = genericSubmitError ? 'Please try again later' : 'Please await form approval to your email';

  return (
    <PaddedSection
      className={genClassName('AppointmentBooking', {
        AppointmentBookingAsModal: props.modalView,
        className: className || '',
      })}
    >
      <div className={'bookingContainer'}>
        <div className='headerSection'>
          <TextOnly className={'textOnly'} heading={heading} content={subHeading} />
        </div>
        <div className={'formSection'}>
          <form onSubmit={handleFormSubmit}>{renderFormContent(props)}</form>
        </div>
        <div className={'calendarSection'}>
          <Calendar
            view='month'
            className={'calendar'}
            onChange={setAppointmentDate}
            value={appointmentDate}
            tileClassName={highlightToDayDate}
            tileDisabled={disableTile}
          />
          <div className='timePickerContainer'>
            <span className='timePickerLabel'>* We use CEST time zone for bookings: {CESTTime}</span>
            <span className={'timePickerLabel'}>Available time slots:</span>
            <TimePicker value={time} onChange={handleTimeChange} errorMessage={formErrorObj?.time} />
          </div>
        </div>
      </div>
      <FormDialog
        content={formSubmitContent}
        linkLabel={'Close'}
        heading={formSubmitHeading}
        onClose={closeAppointmentSuccessDialog}
        error={!!genericSubmitError}
        visible={appointmentSubmitted || !!genericSubmitError}
      />
    </PaddedSection>
  );
};

export default AppointmentBooking;
