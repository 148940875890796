import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { AppointmentBookingPageType, HowOurPricingWorksColumn } from '../../../utils/types';
import { HowOurPricingWorks } from '../../templates';

import './styles.css';

type Props = {
  content: {
    bookMeetingPage: AppointmentBookingPageType,
    landingPage: {
      howOurPricingWorks: {
        heading: string;
        subHeading: string;
        buttonLabel: string;
        href: string;
        columns: HowOurPricingWorksColumn[];
      };
    };
  };
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const HowOurPricingWorksView: React.FC<Props> = (props: Props) => {
  const { heading, subHeading, columns, buttonLabel, href } = props.content.landingPage.howOurPricingWorks;
  return (
    <HowOurPricingWorks
      heading={heading}
      subHeading={subHeading}
      columns={columns}
      buttonLabel={buttonLabel}
      href={href}
      appointmentBookingViewContent={props.content.bookMeetingPage}
    />
  );
};

export default withLanguageContext(HowOurPricingWorksView);
