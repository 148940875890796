export const timePickerHours = [
    '9.00 am',
    '9.15 am',
    '9.30 am',
    '9.45 am',
    '10.00 am',
    '10.15 am',
    '10.30 am',
    '10.45 am',
    '11.00 am',
    '11.15 am',
    '11.30 am',
    '11.45 am',
    '12.00 pm',
    '12.15 pm',
    '12.30 pm',
    '12.45 pm',
    '13.00 pm',
    '13.15 pm',
    '13.30 pm',
    '13.45 am',
    '14.00 am',
    '14.15 am',
    '14.30 pm',
    '14.45 pm',
    '15.00 pm',
    '15.15 pm',
    '15.30 pm',
    '15.45 pm',
    '16.00 pm',
    '16.15 pm',
    '16.30 pm',
    '16.45 pm',
    '17.00 pm',
    '17.15 pm',
    '17.30 pm',
    '17.45 pm',
    '18.00 pm',
  ];
