import React, { useState } from "react";
import { Highlight } from "../../atoms";
import ReactGA from "react-ga4";
import { FbPricingTableProps } from "../../views/FbPricingTableView/FbPricingTableView";

import "./styles.css";

const WB_DISCOUNT_MULTIPLIER_0 = 1;
const WB_DISCOUNT_MULTIPLIER_1 = 0.7;
const WB_DISCOUNT_MULTIPLIER_2 = 0.4;

const STD_DISCOUNT_MULTIPLIER_0 = 1;
const STD_DISCOUNT_MULTIPLIER_1 = 0.7;
const STD_DISCOUNT_MULTIPLIER_2 = 0.4;

const BaMin = 1050;
const IfosMin = 350;
const MIN_PRICE = BaMin + IfosMin;

const CUT_OFF_1 = 5;
const CUT_OFF_2 = 10;

const FB_PRICE = 210;
const WB_ADDITION = 70;
const HOSTING = 70;

const COST_STANDARD = FB_PRICE + HOSTING;

enum SLA_PRICE {
	level_1 = 0.0,
	level_2 = 0.2,
	level_3 = 0.4,
}

type SLA = "level_1" | "level_2" | "level_2";

const calculateMonthlyFee = (acTotal: number, withDiscounts = false) => {

	const discountStep1 = withDiscounts ? STD_DISCOUNT_MULTIPLIER_1 : 1;
	const discountStep2 = withDiscounts ? STD_DISCOUNT_MULTIPLIER_2 : 1;

	if (acTotal <= CUT_OFF_1) {
	 	return	acTotal * STD_DISCOUNT_MULTIPLIER_0 * COST_STANDARD;
	};

	if (acTotal > CUT_OFF_1 && acTotal <= CUT_OFF_2) {
		return MIN_PRICE + (acTotal - CUT_OFF_1) * COST_STANDARD * discountStep1;
	};

	if (acTotal > CUT_OFF_2) {
		return MIN_PRICE + ((CUT_OFF_2 - CUT_OFF_1) * (COST_STANDARD * discountStep1)) + (acTotal - CUT_OFF_2) * COST_STANDARD * discountStep2;
	}

	return 0;
}

const calculateMonthlyWBAddition = (wbTotal: number, withDiscounts = false) => {
	const discountStep0 = withDiscounts ? WB_DISCOUNT_MULTIPLIER_0 : 1;
	const discountStep1 = withDiscounts ? WB_DISCOUNT_MULTIPLIER_1 : 1;
	const discountStep2 = withDiscounts ? WB_DISCOUNT_MULTIPLIER_2 : 1;

	if (wbTotal <= CUT_OFF_1) {
		return WB_ADDITION * wbTotal;
	};

	const minPrice = WB_ADDITION * CUT_OFF_1 * discountStep0;

	if (wbTotal > CUT_OFF_1 && wbTotal <= CUT_OFF_2) {
		return minPrice + ((wbTotal - CUT_OFF_1) * (WB_ADDITION * discountStep1));
	};

	if (wbTotal > CUT_OFF_2) {
		return minPrice + ((CUT_OFF_2 - CUT_OFF_1) * (WB_ADDITION * discountStep1)) + (wbTotal - CUT_OFF_2) * (WB_ADDITION * discountStep2)
	}

	return 0;
}


/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const FbPricingTable: React.FC<FbPricingTableProps> = (props: FbPricingTableProps) => {
	const [smallAircraftNum, setSmallAircraftNum] = useState<number | "">("");
	const [largeAircraftNum, setLargeAircraftNum] = useState<number | "">("");
	const [SLASupport, setSLASupport] = useState<SLA>("level_1");


	const onChange = (field: string, callback: (value: number | "") => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
			.replace(/[^0-9.]/g, "")
			.replace(/(\..*)\./g, "$1");
		callback(value ? parseInt(value) : "");
		ReactGA.event({ category: "Pricing", action: "Change price", label: `${field}: entered ${value ? parseInt(value) : 0}` });
	}

	const smallAircraftNumber = smallAircraftNum || 0;
	const largeAircraftNumber = largeAircraftNum || 0;

	const totalAircrafts = smallAircraftNumber + largeAircraftNumber;

	const slaMultiplier = SLA_PRICE[SLASupport] + 1;

	// Discounted costs
	const monthlyFeeDiscounted = calculateMonthlyFee(totalAircrafts, true);
	const monthlyWbAdditionDiscounted = calculateMonthlyWBAddition(largeAircraftNumber, true);
	const FbDiscountedCostPerMonth = Math.max(monthlyFeeDiscounted + monthlyWbAdditionDiscounted, MIN_PRICE);
	const FbDiscountedCostPerYear = FbDiscountedCostPerMonth * 12;

	const FbDiscountedCostPerAircraftPerMonth = FbDiscountedCostPerMonth / (totalAircrafts || 0) || 0;
	const FbDiscountedCostPerAircraftPerYear = FbDiscountedCostPerYear / (totalAircrafts || 0) || 0;;

	const SLADiscountedCostPerMonth = FbDiscountedCostPerMonth * SLA_PRICE[SLASupport];
	const SLADiscountedCostPerYear = SLADiscountedCostPerMonth * 12;

	const SLADiscountedCostPerAircraftPerMonth = SLADiscountedCostPerMonth / totalAircrafts;
	const SLADiscountedCostPerAircraftPerYear = SLADiscountedCostPerYear / totalAircrafts;

	const totalDiscountedCostPerMonth = Math.max(FbDiscountedCostPerMonth * slaMultiplier, MIN_PRICE);
	const totalDiscountedCostPerYear = totalDiscountedCostPerMonth * 12;
	const totalDiscountedCostPerAircraftPerMonth = FbDiscountedCostPerAircraftPerMonth * slaMultiplier;
	const totalDiscountedCostPerAircraftPerYear = totalDiscountedCostPerAircraftPerMonth * 12;

	const renderPrice = (price: number, showBelowMin?: boolean) => {
		const emptyPrice = <><span>-</span><span>€</span></>;

		return (
			<div className="priceWrap">
				{price && (showBelowMin || FbDiscountedCostPerMonth > MIN_PRICE) ? (
					new Intl.NumberFormat("de-DE", {
						style: "currency",
						currency: "EUR",
					}).format(price).replace(/\./gi, " ")
				) : emptyPrice}
			</div>
		);
	};

	const airCraftsEntered = totalAircrafts > 0;

	return (
		<div className="FbPricingTable">
			<h2>{props.heading}</h2>
			<div className="subtitle" dangerouslySetInnerHTML={{ __html: props.subHeading }} />
			<div className="PricingTableInner">
				<table>
					<thead>
						<tr>
							<th className="text-left">{props.table.head.product}</th>
							<th>{props.table.head.ac}</th>
							<th colSpan={3}>{props.table.head.price}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						<tr>
							{props.table.subHead.map((row, index) => (<td key={index}>{row}</td>))}
						</tr>
						<tr>
							<td rowSpan={2}>{props.table.rows.FlightBriefing}</td>
							<td rowSpan={2}>
								<div className="inputWrap">
									<input
										type="text"
										inputMode="numeric"
										placeholder="number of aircrafts < 100t"
										value={smallAircraftNum ?? ""}
										onChange={onChange("FB: Small Aircraft < 100t", setSmallAircraftNum)}
									/>
									<Highlight
										clasName="info"
									>
										{props.hintStd}
									</Highlight>
								</div>
								<div className="inputWrap">
									<input
										type="text"
										inputMode="numeric"
										placeholder="number of aircrafts > 100t"
										value={largeAircraftNum ?? ""}
										onChange={onChange("FB: Large Aircraft > 100t", setLargeAircraftNum)}
									/>
									<Highlight
										clasName="info"
									>
										{props.hintWb}
									</Highlight>
								</div>
							</td>
							<td rowSpan={2} className="text-right">{renderPrice(FbDiscountedCostPerMonth, airCraftsEntered)}</td>
							<td rowSpan={2} className="text-right">{renderPrice(FbDiscountedCostPerYear, airCraftsEntered)}</td>
							<td rowSpan={2} className="text-right">{renderPrice(FbDiscountedCostPerAircraftPerMonth, airCraftsEntered)}</td>
							<td rowSpan={2} className="text-right">{renderPrice(FbDiscountedCostPerAircraftPerYear, airCraftsEntered)}</td>
						</tr>
						<tr />
						<tr>
							<td>
								{props.table.rows.support}
								<Highlight clasName="supportLevelInfo" mobileModal>
									<div dangerouslySetInnerHTML={{ __html: props.supportHint }}></div>
								</Highlight>
							</td>
							<td>
								<select
									value={SLASupport}
									onChange={(e) => {
										setSLASupport(e.target.value as SLA);
									}}
								>
									<option value="level_1">Level 1 (included)</option>
									<option value="level_2">Level 2</option>
									<option value="level_3">Level 3</option>
								</select>
							</td>
							<td className="text-right">{renderPrice(SLADiscountedCostPerMonth, airCraftsEntered)}</td>
							<td className="text-right">{renderPrice(SLADiscountedCostPerYear, airCraftsEntered)}</td>
							<td className="text-right">{renderPrice(SLADiscountedCostPerAircraftPerMonth, airCraftsEntered)}</td>
							<td className="text-right">{renderPrice(SLADiscountedCostPerAircraftPerYear, airCraftsEntered)}</td>
						</tr>
						<tr>
							<td>
								{props.table.rows.licenses}
							</td>
							<td />
							<td className="text-right"><b>{renderPrice(FbDiscountedCostPerMonth, airCraftsEntered)}</b></td>
							<td className="text-right"><b>{renderPrice(FbDiscountedCostPerYear, airCraftsEntered)}</b></td>
							<td className="text-right"><b>{renderPrice(FbDiscountedCostPerAircraftPerMonth, airCraftsEntered)}</b></td>
							<td className="text-right"><b>{renderPrice(FbDiscountedCostPerAircraftPerYear, airCraftsEntered)}</b></td>
						</tr>
						<tr className="highligted">
							<td><b>{props.table.rows.sum}</b></td>
							<td className={`${totalAircrafts > 0 ? "highlitedField" : ""} text-right`}>{totalAircrafts ? <b>{totalAircrafts}</b> : null}</td>
							<td className={`${totalDiscountedCostPerMonth && airCraftsEntered ? "highlitedField" : ""} text-right`}><b>{renderPrice(totalDiscountedCostPerMonth, airCraftsEntered)}</b></td>
							<td className={`${totalDiscountedCostPerYear && airCraftsEntered ? "highlitedField" : ""} text-right`}><b>{renderPrice(totalDiscountedCostPerYear, airCraftsEntered)}</b></td>
							<td className="text-right"><b>{renderPrice(totalDiscountedCostPerAircraftPerMonth, airCraftsEntered)}</b></td>
							<td className="text-right"><b>{renderPrice(totalDiscountedCostPerAircraftPerYear, airCraftsEntered)}</b></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default FbPricingTable;
