import React, { Component } from 'react';
import { PaddedBlock } from './components/atoms';
import { HeaderView, FooterView, LinkedInMediaView } from './components/views';

class SocialMediaPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main>
        <HeaderView opaque={true} />
        <PaddedBlock padding={80} />
        <LinkedInMediaView />
        <FooterView />
      </main>
    );
  }
}

export default SocialMediaPage;
