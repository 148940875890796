import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { VideoBoxWithText } from '../../templates';

import './styles.css';

type Props = {
	content: {
		landingPage: {
			revisionCycle: {
				content: string;
				heading: string;
				videoId: string;
				image: {
					src: string;
					alt: string;
				}
			}
		}
	};
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const RevisionCycleView: React.FC<Props> = (props: Props) => {

	const {
        heading,
        content,
        videoId,
		image
    } = props.content.landingPage.revisionCycle;

	return (
		<VideoBoxWithText
			heading={heading}
			content={content}
			videoId={videoId}
			image={image}
			reversed
		/>
	);

};

export default withLanguageContext(RevisionCycleView);
