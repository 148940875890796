import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { TextImageThird } from '../../templates';
import { ImageType } from '../../../utils/types';

import './styles.css';

type Props = {
    content: {
        landingPage: {
            ourGrowingFleet: {
                heading: string;
                content: string;
                images: ImageType[];
            }
        }
    }
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const OurGrowingFleetView: React.FC<Props> = (props) => {

    const {
        heading,
        content,
        images,
    } = props.content.landingPage.ourGrowingFleet;

    return (
        <TextImageThird
            {...{
                heading,
                content,
                images,
            }}
            className='OurGrowingFleetView'
        />
    );

};

export default withLanguageContext(OurGrowingFleetView);
