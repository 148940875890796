import React from 'react';
import { genClassName } from '../../../utils/helpers';

import './styles.css';

type Props = {
  children: string | React.ReactNode;
  onClick: () => void;
  className?: string;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const DivAsButton: React.FC<Props> = ({ children, onClick, className = '' }: Props) => {
  return (
    <div onClick={onClick} className={genClassName('DivAsButton', className)}>
      {children}
    </div>
  );
};

export default DivAsButton;
