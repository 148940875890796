import React, { Component } from 'react';
import classNames from 'classnames';
import { NavItem } from '../../atoms';
import { NavigationItem } from '../../../utils/types';
import './styles.css';

type Props = {
    className: string;
    navItems: NavigationItem[]
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class NavItemGroup extends Component<Props, {}> {
  genClassName = (props: Props) => classNames('NavItemGroup', props.className);

  genLiClassName = (hasSubItems: boolean, displayAsButton: boolean) => classNames({subItemLi: hasSubItems, displayAsButton })

  mapChildren = ({ children, href, label, subItems, displayAsButton }: NavigationItem) => (
    <li className={this.genLiClassName(!!subItems?.length, !!displayAsButton)} key={children.toString()}>
      {href ?
        <NavItem href={href}>
          <>
            {label ? <span className='subItemLabel'>{label}</span> : null }
            {children}
          </>
        </NavItem> :
        <span>{children}</span>
      }
      {subItems?.length && <span className={'subItemLiIcon'} />}
      {subItems?.length && <ul className={'subItemContainer'}>
          {subItems.map(this.mapSubItems)}
        </ul>}
    </li>
  );

  mapSubItems =({ children, href }: NavigationItem) => (
    <li className={'subItemsListItem'} key={children.toString()}>
        <NavItem href={href}>{children}</NavItem>
    </li>
  )

  render() {
    const className = this.genClassName(this.props);

    return (
      <nav className={className}>
        <ul>{this.props.navItems.map(this.mapChildren)}</ul>
      </nav>
    );
  }
}
