import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { TextImageSecond } from '../../templates';

import './styles.css';

type Props = {
    content: {
        landingPage: {
            allTools: {
                heading: string;
                content: string;
                subheading: string;
            }
        }
    }
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const AllToolsInOneSpaceView: React.FC<Props> = (props: Props) => {

	const {
        heading,
        content,
        subheading,
    } = props.content.landingPage.allTools;

	return (
		<TextImageSecond
			{...{
				heading,
				content,
				subheading,

			}}
			headingFirst
			subHeadingStyle='grey'
			className="AllToolsInOneSpaceView"
		/>
	);

};

export default withLanguageContext(AllToolsInOneSpaceView);
