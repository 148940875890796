import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { ContactUs } from '../../templates';
import { ContactForm } from '../../molecules';

type Props = {
    content: {
        landingPage: {
            contactUs: {
                buttonLabel: string;
                termsContent: string;
            }
        },
        common: {
            backHref: string;
            backLabel: string;
            confirmPasswordLabel: string;
            confirmPasswordValidationMessage: string;
            emailLabel: string;
            firstNameLabel: string;
            lastNameLabel: string;
            messageLabel: string;
            organizationLabel: string;
            organizationRoleLabel: string;
            passwordLabel: string;
            passwordValidationMessage: string;
            serverErrorContent: string;
            serverErrorMessage: string;
            serverSuccessContent: string;
            serverSuccessMessage: string;
            validationMessage: string;
        }
    }
};

/**
 * The contact us section of the landing page.
 *
 * @typedef {Object} Props
 *
 * @augments {Component<Props>}
 */
const ContactUsView: React.FC<Props> = (props: Props) => {
    const { buttonLabel, termsContent } = props.content.landingPage.contactUs;
    const common = props.content.common;

    return (
        <ContactUs>
            <ContactForm {...{ ...common, buttonLabel, termsContent }} />
        </ContactUs>
    );

};

export default withLanguageContext(ContactUsView);
