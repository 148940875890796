export const API = {
	basePath: process.env.REACT_APP_DOCBROWSER_API_URL,
	strapiPath: process.env.REACT_APP_STRAPI_API_URL,
}

export const FIELD_NAME_MAP = {
    email: 'emailLabel',
    first_name: 'firstNameLabel',
    last_name: 'lastNameLabel',
    'organisation_attributes.org_name': 'organizationLabel',
    password: 'passwordLabel',
    password_repeat: 'confirmPasswordLabel',
};

export const REGEX = {
    name: '^\\S.*',
    orgName: '^\\S.*',
    password: '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s).{6,73}$',
};

export const SW = {
    updateEvent: 'swupdate',
};
