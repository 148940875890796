import React, { Component } from 'react';
import ContentConverter from '../../../utils/contentConverter';
import { Dialog } from '../../atoms';

import './styles.css';

type MyProps = {
	buttonLabel: string,
	content: string,
	heading: string,
	signUpLink: string,
	signUpText: string,
	taggisLink: string,
	taggisText: string,
};

type MyState = {
	isVisible: boolean;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class CoronaInfo extends Component<MyProps, MyState> {

	state = {
		isVisible: false,
	}

	handleClose = () => {
		this.setState({ isVisible: false });
	}

	handleOpen = () => {
		this.setState({ isVisible: true });
	}

	/**
	 * @param {String} heading
	 */
	getAddress = (heading: string) => new ContentConverter(heading).nlToBr().convertLinks().getReactHtml();

	render () {
		return (
			<div className='CoronaInfo'>
				<button onClick={this.handleOpen}>{this.props.buttonLabel}</button>
				<Dialog className='CoronaDialog' onClose={this.handleClose} visible={this.state.isVisible}>
					<button className='CoronaDialog-close-button' onClick={this.handleClose} type='button' />
					<div className="CoronaDialog-inner">
						<h3>{this.props.heading}</h3>
						<div
							dangerouslySetInnerHTML={this.getAddress(this.props.content)}
						/>
						<div className="CoronaDialog-button-wrap">
							<a className="CoronaDialog-button" href={this.props.signUpLink}>{this.props.signUpText}</a>
							<a className="CoronaDialog-button" rel="noopener noreferrer" target="_blank" href={this.props.taggisLink}>{this.props.taggisText}</a>
						</div>
					</div>
				</Dialog>
			</div>
		);
	}

}
