import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import MDSpinner from 'react-md-spinner';

import './styles.css';

type Props = {
    disabled?: boolean,
    href?: string,
    submitting?: boolean,
    type?: "button" | "submit" | "reset" | undefined;
    className: string;
    onClick?: (event: React.SyntheticEvent) => void;
    children: React.ReactNode;
}

/**
 * A simple button component.
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class Button extends Component<Props, {}> {

    genClassName = (props: Props) => classNames('Button', props.className, {
        'Button-link': props.href,
    });

    /** @param {React.SyntheticEvent} event */
    handleClick = (event: React.SyntheticEvent) => this.props.onClick && this.props.onClick(event);

    render () {
        const { children, disabled, href, submitting, type } = this.props;
        const className = this.genClassName(this.props);

        return href
            ? (
                <Link
                    className={className}
                    to={href}
                >
                    {children}
                </Link>
            )
            : (
                <button
                    className={className}
                    disabled={disabled || submitting}
                    onClick={this.handleClick}
                    type={type}
                >
                    {submitting ? <MDSpinner singleColor='white' size={18} /> : children}
                </button>
            );
    }
}
