import React, { useState } from "react";
import { Highlight } from "../../atoms";
import { PricingTableProps } from "../../views/PricingTableView/PricingTableView";
import ReactGA from "react-ga4";

import "./styles.css";

const L1Top = 5000;
const L2Top = 10000;
const L3Top = 20000;
const L4Top = 30000;
const L5Top = 50000;
const L6Top = 70000;
const L7Top = 90000;
const L8Top = 110000;
const L9Top = 130000;
const L10Top = 150000;

const L1Br = 5000;
const L2Br = 5000;
const L3Br = 10000;
const L4Br = 10000;
const L5Br = 20000;
const L6Br = 20000;
const L7Br = 20000;
const L8Br = 20000;
const L9Br = 20000;
const L10Br = 20000;

const L2Factor = 0.9; // percent
const L3Factor = 0.8; // percent
const L4Factor = 0.7; // percent
const L5Factor = 0.6; // percent
const L6Factor = 0.5; // percent
const L7Factor = 0.4; // percent
const L8Factor = 0.3; // percent
const L9Factor = 0.2; // percent
const L10Factor = 0.2; // percent
const L11Factor = 0.2; // percent

const I_DOC_BROWSER_PRICE = 4;
const I_FLY_THERE_PRICE = 3;
const I_RE_FORM_PRICE = 2;

const MIN_PRICE = 2400;

enum SLA_PRICE {
  level_1 = 0.3,
  level_2 = 0.6,
  level_3 = 0.9,
}

type SLA = "level_1" | "level_2" | "level_2";

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const PricingTable: React.FC<PricingTableProps> = (props: PricingTableProps) => {
  const [iDocBrowserUsers, setIDocBrowserUsers] = useState<number | "">("");
  const [iFlyThereUsers, setIFlyThereUsers] = useState<number | "">("");
  const [iReFormUsers, setIReFormUsers] = useState<number | "">("");
  const [SLASupport, setSLASupport] = useState<SLA>("level_1");


  const getCostPerYear = (TotalBasePrice: number, SLAPerYear: number) => {

    let sum = 0;
    if (!TotalBasePrice) return sum;

    sum += TotalBasePrice <= L1Top ? TotalBasePrice : L1Br;
    sum += TotalBasePrice <= L2Top ? Math.max((TotalBasePrice-L1Top)*L2Factor,0) : L2Br * L2Factor;
    sum += TotalBasePrice <= L3Top ? Math.max((TotalBasePrice-L2Top)*L3Factor,0) : L3Br * L3Factor;
    sum += TotalBasePrice <= L4Top ? Math.max((TotalBasePrice-L3Top)*L4Factor,0) : L4Br * L4Factor;
    sum += TotalBasePrice <= L5Top ? Math.max((TotalBasePrice-L4Top)*L5Factor,0) : L5Br * L5Factor;
    sum += TotalBasePrice <= L6Top ? Math.max((TotalBasePrice-L5Top)*L6Factor,0) : L6Br * L6Factor;
    sum += TotalBasePrice <= L7Top ? Math.max((TotalBasePrice-L6Top)*L7Factor,0) : L7Br * L7Factor;
    sum += TotalBasePrice <= L8Top ? Math.max((TotalBasePrice-L7Top)*L8Factor,0) : L8Br * L8Factor;
    sum += TotalBasePrice <= L9Top ? Math.max((TotalBasePrice-L8Top)*L9Factor,0) : L9Br * L9Factor;
    sum += TotalBasePrice <= L10Top ? Math.max((TotalBasePrice-L9Top)*L10Factor,0) : L10Br * L10Factor;

    sum += Math.max((TotalBasePrice-L10Top)*L11Factor,0)
    return sum;
  }

  const formatUsers = (value: number) => {
    return new Intl.NumberFormat("en-US").format(value).replace(/,/g, " ")
  }

  const onChange = (field: string, callback: (value: number | "") => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");
      callback(value ? parseInt(value) : "");
      ReactGA.event({ category: "Pricing", action: "Change price", label: `${field}: entered ${value ? parseInt(value) : 0 }`});
  }

  const onMaxExceededClose = () => {
    setIDocBrowserUsers("");
    setIFlyThereUsers("");
    setIReFormUsers("");
  }

  const iDocBrowserUsersNumber = iDocBrowserUsers || 0;
  const iFlyThereUsersNumber = iFlyThereUsers || 0;
  const iReFormUsersNumber = iReFormUsers || 0;

  const allUsersSum = iDocBrowserUsersNumber + iFlyThereUsersNumber + iReFormUsersNumber;

  const iDocBrowserCostPerMonth = iDocBrowserUsers ? I_DOC_BROWSER_PRICE * iDocBrowserUsersNumber : 0;
  const iDocBrowserCostPerYear = iDocBrowserCostPerMonth * 12;

  const iFlyThereCostPerMonth = iFlyThereUsers ? I_FLY_THERE_PRICE * iFlyThereUsersNumber : 0;
  const iFlyThereCostPerYear = iFlyThereCostPerMonth * 12;

  const iReFormCostPerMonth = iReFormUsers ? I_RE_FORM_PRICE * iReFormUsersNumber : 0;
  const iReFormCostPerYear = iReFormCostPerMonth * 12;

  const SLAPerMonth = allUsersSum * SLA_PRICE[SLASupport];
  const SLAPerYear = SLAPerMonth * 12;

  const subTotalPerMonth = iDocBrowserCostPerMonth + iFlyThereCostPerMonth + iReFormCostPerMonth;
  const appsPerYear = iDocBrowserCostPerYear + iFlyThereCostPerYear + iReFormCostPerYear
  const subTotalPerYear = appsPerYear !== 0 && appsPerYear < MIN_PRICE ? MIN_PRICE : appsPerYear;

  const totalPerMonth = subTotalPerMonth + SLAPerMonth;
  const totalPerYear = subTotalPerYear + SLAPerYear;
  const totalDiscountedCostPerYear = getCostPerYear(totalPerYear, SLAPerYear);

  const discount = totalPerYear > 0 ? (1 - totalDiscountedCostPerYear/totalPerYear) * 100 : 0;

  const iDocBrowserDiscountedCostPerYear = Math.abs(iDocBrowserCostPerYear - (iDocBrowserCostPerYear / 100 * discount));
  const iDocBrowserDiscountedCostPerMonth = iDocBrowserDiscountedCostPerYear / 12;
  const iDocBrowserDiscountedCostPerUserPerMonth = iDocBrowserDiscountedCostPerMonth / (iDocBrowserUsersNumber || 0) || 0;

  const iFlyThereDiscountedCostPerYear = Math.abs(iFlyThereCostPerYear - (iFlyThereCostPerYear / 100 * discount));
  const iFlyThereDiscountedCostPerMonth = iFlyThereDiscountedCostPerYear / 12;
  const iFlyThereDiscountedCostPerUserPerMonth = iFlyThereDiscountedCostPerMonth / (iFlyThereUsersNumber || 0) || 0;

  const iReFormDiscountedCostPerYear = Math.abs(iReFormCostPerYear - (iReFormCostPerYear / 100 * discount));
  const iReFormDiscountedCostPerMonth = iReFormDiscountedCostPerYear / 12;
  const iReFormDiscountedCostPerUserPerMonth = iReFormDiscountedCostPerMonth / (iReFormUsersNumber || 0) || 0;

  const SLADiscountedCostPerYear = Math.abs(SLAPerYear - (SLAPerYear / 100 * discount));
  const SLADiscountedCostPerMonth = SLADiscountedCostPerYear / 12;
  const SLADiscountedCostPerUserPerMonth = SLADiscountedCostPerMonth / allUsersSum;

  const appsDiscountedPerYear = iDocBrowserDiscountedCostPerYear + iFlyThereDiscountedCostPerYear + iReFormDiscountedCostPerYear;
  const subTotalDiscountedCostPerYear = appsDiscountedPerYear !== 0 && appsDiscountedPerYear < MIN_PRICE ? MIN_PRICE : appsDiscountedPerYear;
  const subTotalDiscountedCostPerMonth = iDocBrowserDiscountedCostPerMonth + iFlyThereDiscountedCostPerMonth + iReFormDiscountedCostPerMonth;
  const subTotalDiscountedCostPerUserPerMonth = subTotalDiscountedCostPerMonth / allUsersSum;

  const totalDiscountedCostPerMonth = subTotalDiscountedCostPerMonth + SLADiscountedCostPerMonth;
  const totalDiscountedCostPerUserPerMonth = subTotalDiscountedCostPerUserPerMonth + SLADiscountedCostPerUserPerMonth;

  const excededMaxUsersAmount = iDocBrowserUsersNumber > 50000 || iFlyThereUsersNumber > 50000 || iReFormUsersNumber > 50000;

  const renderPrice = (price: number, showBelowMin?: boolean) => {
    const emptyPrice = <><span>-</span><span>€</span></>;

    return (
      <div className="priceWrap">
        {price && (showBelowMin || subTotalDiscountedCostPerYear > MIN_PRICE) && !excededMaxUsersAmount ? (
          new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
          }).format(price).replace(/\./gi, " ")
        ) : emptyPrice}
      </div>
    );
  };

  return (
    <div className="PricingTable">
      <h2>{props.heading}</h2>
      <div className="subtitle" dangerouslySetInnerHTML={{__html: props.subHeading}} />
      <div className="PricingTableInner">
        <table>
          <thead>
            <tr>
              <th className="text-left">{props.table.head.product}</th>
              <th>{props.table.head.users}</th>
              <th colSpan={2}>{props.table.head.basePrice}</th>
              <th>{props.table.head.discount}</th>
              <th colSpan={3}>{props.table.head.discountedPrice}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {props.table.subHead.map((row, index) => (<td key={index}>{row}</td>))}
            </tr>
            <tr>
              <td>{props.table.rows.iDocBrowser}</td>
              <td>
                <div className="inputWrap">
                  <input
                    type="text"
                    inputMode="numeric"
                    placeholder="users number"
                    value={iDocBrowserUsers ? formatUsers(iDocBrowserUsers) : ""}
                    onChange={onChange("IDocBrowser field", setIDocBrowserUsers)}
                  />
                  <Highlight
                    clasName="info"
                  >
                    {props.hint}
                  </Highlight>
                </div>
              </td>
              <td className="text-right">{renderPrice(iDocBrowserCostPerMonth)}</td>
              <td className="text-right">{renderPrice(iDocBrowserCostPerYear)}</td>
              <td></td>
              <td className="text-right">{renderPrice(iDocBrowserDiscountedCostPerMonth)}</td>
              <td className="text-right">{renderPrice(iDocBrowserDiscountedCostPerYear)}</td>
              <td className="text-right">{renderPrice(iDocBrowserDiscountedCostPerUserPerMonth)}</td>
            </tr>
            <tr>
              <td>{props.table.rows.iFlyThere}</td>
              <td>
                <div className="inputWrap">
                  <input
                    type="text"
                    inputMode="numeric"
                    placeholder="users number"
                    value={iFlyThereUsers ? formatUsers(iFlyThereUsers) : ""}
                    onChange={onChange("iFlyThereUsers field", setIFlyThereUsers)}
                  />
                  <Highlight
                    clasName="info"
                  >
                    {props.hint}
                  </Highlight>
                </div>
              </td>
              <td className="text-right">{renderPrice(iFlyThereCostPerMonth)}</td>
              <td className="text-right">{renderPrice(iFlyThereCostPerYear)}</td>
              <td></td>
              <td className="text-right">{renderPrice(iFlyThereDiscountedCostPerMonth)}</td>
              <td className="text-right">{renderPrice(iFlyThereDiscountedCostPerYear)}</td>
              <td className="text-right">{renderPrice(iFlyThereDiscountedCostPerUserPerMonth)}</td>
            </tr>
            <tr>
              <td>{props.table.rows.iReForm}</td>
              <td>
                <div className="inputWrap">
                  <input
                    type="text"
                    inputMode="numeric"
                    placeholder="users number"
                    value={iReFormUsers ? formatUsers(iReFormUsers) : ""}
                    onChange={onChange("iReFormUsers field", setIReFormUsers)}
                  />
                  <Highlight
                    clasName="info"
                  >
                    {props.hint}
                  </Highlight>
                </div>
              </td>
              <td className="text-right">{renderPrice(iReFormCostPerMonth)}</td>
              <td className="text-right">{renderPrice(iReFormCostPerYear)}</td>
              <td></td>
              <td className="text-right">{renderPrice(iReFormDiscountedCostPerMonth)}</td>
              <td className="text-right">{renderPrice(iReFormDiscountedCostPerYear)}</td>
              <td className="text-right">{renderPrice(iReFormDiscountedCostPerUserPerMonth)}</td>
            </tr>
            <tr className="highligted subtotal">
              <td><b>{props.table.rows.Subtotal}</b></td>
              <td className={`${allUsersSum > 0 ? "highlitedField" : ""} text-right`}>{allUsersSum ? <b>{formatUsers(allUsersSum)}</b> : null}</td>
              <td className="text-right">{renderPrice(subTotalPerMonth)}</td>
              <td className="text-right">{renderPrice(subTotalPerYear)}</td>
              <td className={`${discount ? "highlitedField" : ""} text-center`}>{allUsersSum && !discount ? "0 %" : discount ? `${Math.ceil(discount)} %` : "- %" }</td>
              <td className="text-right"><b>{renderPrice(subTotalDiscountedCostPerMonth)}</b></td>
              <td className="text-right"><b>{renderPrice(subTotalDiscountedCostPerYear, true)}</b></td>
              <td className="text-right"><b>{renderPrice(subTotalDiscountedCostPerUserPerMonth)}</b></td>
            </tr>
            <tr>
              <td>
              {props.table.rows.support}
                <Highlight clasName="supportLevelInfo" mobileModal>
                  <div dangerouslySetInnerHTML={{__html: props.supportHint}}></div>
                </Highlight>
              </td>
              <td>
                <select
                  value={SLASupport}
                  onChange={(e) => {
                    setSLASupport(e.target.value as SLA);
                  }}
                >
                  <option value="level_1">Level 1</option>
                  <option value="level_2">Level 2</option>
                  <option value="level_3">Level 3</option>
                </select>
              </td>
              <td className="text-right">{renderPrice(SLAPerMonth, true)}</td>
              <td className="text-right">{renderPrice(SLAPerYear, true)}</td>
              <td></td>
              <td className="text-right">{renderPrice(SLADiscountedCostPerMonth, true)}</td>
              <td className="text-right">{renderPrice(SLADiscountedCostPerYear, true)}</td>
              <td className="text-right">{renderPrice(SLADiscountedCostPerUserPerMonth, true)}</td>
            </tr>
            <tr className="highligted">
              <td><b>{props.table.rows.sum}</b></td>
              <td className={`${allUsersSum > 0 ? "highlitedField" : ""} text-right`}>{allUsersSum ? <b>{formatUsers(allUsersSum)}</b> : null}</td>
              <td className="text-right"><b>{renderPrice(totalPerMonth)}</b></td>
              <td className="text-right"><b>{renderPrice(totalPerYear)}</b></td>
              <td></td>
              <td className="text-right"><b>{renderPrice(totalDiscountedCostPerMonth)}</b></td>
              <td className={`${totalDiscountedCostPerYear ? "highlitedField" : ""} text-right`}><b>{renderPrice(totalDiscountedCostPerYear, true)}</b></td>
              <td className="text-right"><b>{renderPrice(totalDiscountedCostPerUserPerMonth)}</b></td>
            </tr>
          </tbody>
        </table>
      </div>

      {excededMaxUsersAmount && (
        <div className="maxExceded">
          <div className="content">
            <i className='close' onClick={onMaxExceededClose} />
            Please  <a href="/#contact">contact us</a> for requests greater than 50,000 users
          </div>
        </div>)
      }
    </div>
  );
};

export default PricingTable;
