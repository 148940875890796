import React, { Component } from 'react';
import classNames from 'classnames';
import './styles.css';

type Props = {
	isOpen: boolean,
	content: string
};

/**
 * AccordionTitle
 *
 * @typedef {Object} Props
 * @prop {boolean} isOpen
 * @prop {string} content
 *
 * @augments {Component<Props>}
 */

export default class AccordionTitle extends Component<Props> {

	genClassName = (props: Props) =>
		classNames('AccordionTitle', { AccordionTitleOpen: props.isOpen });

	render () {
		const { content } = this.props;
		return <h3 className={this.genClassName(this.props)}>{content}</h3>;
	}
}
