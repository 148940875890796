import React, { Component } from 'react';
import classNames from 'classnames';

import './styles.css';

type Props = {
    className?: string;
    open: boolean;
    onClick: (event: React.SyntheticEvent) => void;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class NavButton extends Component<Props, {}> {
    handleClick = (event: React.SyntheticEvent) => this.props.onClick(event);

    genClassName = (props: Props) => classNames('NavButton', props.className, {
        open: props.open,
    });

    render () {
        return (
            <button
                className={this.genClassName(this.props)}
                aria-pressed={this.props.open}
                onClick={this.props.onClick && this.handleClick}
            />
        );
    }

}
