import classNames from 'classnames';

export const chunkInefficient = (chunkSize: number, array: any) => {
    return [].concat.apply([],
        array.map(function(elem: any, i: number) {
            return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
        })
    );
}

export const genClassName = (defaultClass: string, additionalClasses: object | string ) =>
    classNames(defaultClass, additionalClasses);

const excludeWeekends = (date: Date) => {
  switch (date.getDay()) {
    //check Saturday
    case 6:
      date.setDate(date.getDate() + 4);
      break;
    //check Sunday
    case 0:
      date.setDate(date.getDate() + 3);
  }
}

export const setTwoDaysInAdvanceDate = () => {
  const date = new Date();
  if(date.getDay() === 4){
    date.setDate(date.getDate() + 4);
    return date;
  }
  if(date.getDay() === 5){
    date.setDate(date.getDate() + 4);
    return date;
  }

  if(date.getDay() === 6 || date.getDay() === 0){
    excludeWeekends(date);
    return date;
  }
  date.setDate(date.getDate() + 2);
  return date;
}

export const getDisabledCalendarDates = (date: Date) => {
  const currentTileDate = new Date(date);
  const currentDateInAdvance = new Date(setTwoDaysInAdvanceDate())

  currentTileDate.setHours(0,0,0,0);
  currentDateInAdvance.setHours(0,0,0,0);

  return currentTileDate < currentDateInAdvance;
}

export const capitalize = (val: string): string => val ? val[0].toUpperCase() + val.slice(1) : val;
