import React, { Component } from 'react';
import { Hero } from '../../templates';
import content from '../../../constants/content';
import './styles.css';
import { SlideType } from '../../../utils/types';

type Props = {
    forwardedRef: React.Ref<HTMLElement>;
};

type State = {
    content: {
        landingPage: {
            hero: {
                slides: SlideType[]
            }
        }
    };
};

/**
 * The hero section of the landing page.
 *
 * @typedef {Object} Props
 * @prop {String} [content]
 * @typedef {Object} State
 *
 * @augments {Component<Props, State>}
 */
class HeroView extends Component<Props, State> {

    state = {
        content: content.en
    }

    render () {
        const {
            slides
        } = this.state.content.landingPage.hero;

        return (
            <Hero
                slides={slides}
                className='HeroView'
                forwardedRef={this.props.forwardedRef}
            />
        );
    }

}

export default HeroView;
