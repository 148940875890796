import React from "react";
import { withLanguageContext } from "../../../utils/LanguageContext";
import { PricingTable } from "../../templates";

export type PricingTableProps = {
  heading: string;
  subHeading: string;
  hint: string;
  supportHint: string;
  table: {
    head: {
      product: string;
      users: string;
      basePrice: string;
      discount: string;
      discountedPrice: string;
    };
    subHead: string[]
    rows: {
      iDocBrowser: string;
      iFlyThere: string;
      iReForm: string;
      Subtotal: string;
      support: string;
      sum: string;
    };
  };
}

type Props = {
  content: {
    pricingPage: {
      pricingTable: PricingTableProps;
    };
  };
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const PricingTableView: React.FC<Props> = (props: Props) => {
  const pricingTable = props.content.pricingPage.pricingTable;
  return <PricingTable {...pricingTable} />;
};

export default withLanguageContext(PricingTableView);
