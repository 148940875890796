import React from 'react';
import { useHistory } from 'react-router-dom';
import { AppointmentBookingPageType, HowOurPricingWorksColumn } from '../../../utils/types';
import { Button, PaddedSection } from '../../atoms';
// import ModalView from '../../views/ModalView';
// import AppointmentBooking from '../AppointmentBooking';

import './styles.css';

type Props = {
  heading: string;
  subHeading: string;
  buttonLabel: string;
  href: string;
  columns: HowOurPricingWorksColumn[];
  appointmentBookingViewContent: AppointmentBookingPageType;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const HowOurPricingWorks: React.FC<Props> = ({
  heading,
  subHeading,
  columns,
  href,
  buttonLabel,
  appointmentBookingViewContent,
}: Props) => {
  const history = useHistory();
  // const [showBookingModal, setShowBookingModal] = useState<boolean>(false);

  const renderColumn = ({ listHeader, listItems }: HowOurPricingWorksColumn, index: number) => (
    <div key={index}>
      <h2>{listHeader}</h2>
      <ul className={'infoList'}>
        {listItems.map((item, index) => (
          <li key={index}>
            <span className='listItemTitle'>{item.title}</span>
            {item.content?.length &&
              item.content.map((item, index) => (
                <span key={index} className='itemContent'>
                  {item}
                </span>
              ))}
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <PaddedSection className='HowOurPricingWorks'>
      {heading ? <h1>{heading}</h1> : ''}
      {subHeading ? <p className={'subHeaderParagraph'}>{subHeading}</p> : ''}
      <div className={'columnsWrap'}>{columns.map(renderColumn)}</div>
      <Button className='bookMeetingButton' onClick={() => {
        // setShowBookingModal(true);
        history.push(href);
        }}>
        {buttonLabel}
      </Button>
      {/* {showBookingModal && (
        <ModalView className={'modalView'} opened={true} closeHandler={() => setShowBookingModal(false)}>
          <AppointmentBooking modalView {...appointmentBookingViewContent} />
        </ModalView>
      )} */}
    </PaddedSection>
  );
};

export default HowOurPricingWorks;
