import classNames from 'classnames';
import React, { useState } from 'react';

import './styles.css';

type Props = {
	children: React.ReactNode;
	clasName?: string;
	mobileModal?: boolean
	invalid?: boolean
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const Highlight: React.FC<Props> = ({children, clasName, mobileModal, invalid}: Props) => {
	const [mobileContentVisible, setMobileContentVisible] = useState(false);

	const infoIcon = require(`../../../assets/svg/info.svg`);
	const invalidInfoIcon = require(`../../../assets/svg/info-invalid.svg`);

	const onMobileHintShow = () => {
		setMobileContentVisible(true);
	};

	const onMobileHintClose = () => {
		setMobileContentVisible(false);
	};

	return (
		<div className={classNames("Highlight", clasName, {
			mobileModal: mobileModal,
			mobileContentVisible: mobileContentVisible
		})}>
			<div className="contentWrap">
				<span className="iconWrap" onClick={onMobileHintShow}>
					<img src={invalid ? invalidInfoIcon : infoIcon} alt="Info Icon" />
				</span>
				<div className="content">
					<div className="contentInner">
						<i className='close' onClick={onMobileHintClose} />
						{children}
					</div>
				</div>
			</div>
		</div>
	);

};

export default Highlight;
