import React, { useEffect, useState } from 'react';
import { getLinkedInPosts, getLinkedInPostsCategories } from '../../../sevices/DOCBrowserApi';
import { LinkedInPostType, OptionType, PostsPaginationType } from '../../../utils/types';
import { PaddedSection } from '../../atoms';
import { Pagination, Select } from '../../molecules';

import './styles.css';

const initialOptions = [{ key: '', value: 'All posts' }];

const initialPagination = {
  page: 1,
  pageSize: 4,
  pageCount: 0,
  total: 0,
};

const categoriesPaginationSize = 100;

const categoryRelationLink = 'db_linkedin_post_categories';

type Props = {
  heading: string;
  linkedInLogo: string;
  videos: string[];
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const LinkedInMedia: React.FC<Props> = () => {
  const [posts, setPosts] = useState<LinkedInPostType[]>([]);
  const [categories, setCategories] = useState<OptionType[]>(initialOptions);
  const [currentCategory, setCurrentCategory] = useState<string>('');
  const [fetchPostsError, setFetchPostsError] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PostsPaginationType>(initialPagination);

  const handleChangeCategory = (option: OptionType) => {
    setCurrentCategory(option.key);
    setPagination({ ...pagination, page: 1 });
  };


  const getParams = () => {
    // eslint-disable-next-line no-useless-escape
    const categoryParam = currentCategory ? `filters\[${categoryRelationLink}\][linkedInPostCategory][$eq]=${currentCategory}` : '';
    const paginationParams = `pagination[page]=${pagination.page}&pagination[pageSize]=${initialPagination.pageSize}`;

    return (categoryParam ? `${categoryParam}&${paginationParams}` : paginationParams) + '&sort[0]=publishedAt:DESC';
  };

  const getPosts = async () => {
    const params = `?${getParams()}`;
    try {
      const res = await getLinkedInPosts(params);
      if (res?.json?.data) {
        setPosts(res.json.data);
      }
      if (res?.json?.meta) {
        setPagination(res.json.meta.pagination);
      }
    } catch (error) {
      setFetchPostsError(!!error);
    }
  };

  const getPostsCategories = async () => {
    try {
      const params = `?pagination[pageSize]=${categoriesPaginationSize}`
      const res = await getLinkedInPostsCategories(params);
      if (res?.json) {
        const categories = res.json.data.map(category => ({key: category.attributes.linkedInPostCategory
          , value: category.attributes.linkedInPostCategory
        }))
        setCategories([...initialOptions, ...categories]);
      }
    } catch (error) {
      setFetchPostsError(!!error);
    }
  };

  useEffect(() => {
    getPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory, pagination.page]);

  useEffect(() => {
    getPostsCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PaddedSection className='LinkedInMedia'>
      <div className='postsTopSection'>
        <Pagination
          className='postsPaginationBar'
          currentPage={pagination.page}
          totalCount={pagination.total}
          pageSize={pagination.pageSize}
          onPageChange={(page: number) => setPagination({ ...pagination, page })}
        />
        <div className='selectWrapper'>
          <Select options={categories || initialOptions} onChange={handleChangeCategory} />
        </div>
      </div>
      <div className='postsWrapper'>
        { fetchPostsError ? (
          <h2 className={'postsError'}>Posts are on the way</h2>
        ) : (
          posts.map(({ id, attributes: { link, title } }) => (
            <iframe className={'post'} key={id} src={link} allowFullScreen frameBorder='0' title={title} />
          ))
        )}
      </div>
    </PaddedSection>
  );
};

export default LinkedInMedia;
