import React from 'react';
import { TextImageSecond } from '../../templates';
import { withLanguageContext } from '../../../utils/LanguageContext';
import './styles.css';

type Props = {
    content: {
        landingPage: {
            whoWeAre: {
                buttonLabel: string;
                content: string;
                heading: string;
                href: string;
                subheading: string;
            }
        }
    }
};

/**
 * Who we are view on landing page.
 *
 * @typedef {Object} Props
 *
 * @augments {Component<Props>}
 */
const WhoWeAreView: React.FC<Props> = (props: Props) => {
    const {
        buttonLabel,
        content,
        heading,
        href,
        subheading,
    } = props.content.landingPage.whoWeAre;

    return (
        <TextImageSecond
            {...{
                buttonLabel,
                content,
                heading,
                href,
                subheading,
            }}
            className="WhoWeAreView"
        />
    );

};

export default withLanguageContext(WhoWeAreView);
