import React from 'react';

import Faq from '../../templates/Faq';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { Question } from '../../../utils/types';

type Props = {
	content: {
		faq: {
			questions: Question[],
			heading: {
				title: string,
				subTitle: string
			}

		}
	};
};


/**
 * The FAQ section of the landing page.
 *
 * @typedef {Object} Props
 * @prop {Object}  content
 *
 * @augments {Component<Props>}
 */

const FaqView: React.FC<Props> = (props: Props) => {
	const { content } = props;

	return <Faq content={content} />;

}

export default withLanguageContext(FaqView);
