import React from 'react';
import './styles.css';

type Props = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const CheckBox: React.FC<Props> = ({ checked, onChange, className = '' }: Props) => {
  return (
    <div className={`Checkbox ${className}`}>
      <input type='checkbox' checked={checked} onChange={(e) => onChange(e.target.checked)} />
      <i />
    </div>
  );
};

export default CheckBox;
