import React from 'react';

import './styles.css';
import { genClassName } from '../../../utils/helpers';

type Props = {
    videoId: string;
    className: string;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const YouTubePlayer: React.FC<Props> = (props: Props) => {

    return (
        <iframe
            allow='autoplay; encrypted-media'
            allowFullScreen={true}
            className={genClassName('YouTubePlayer', props.className)}
            frameBorder='0'
            src={`https://www.youtube.com/embed/${props.videoId}?rel=0&showinfo=0&autoplay=1`}
            title={`YouTube video ${props.videoId}`}
        />
    );

};

export default YouTubePlayer;
