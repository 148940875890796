import React from 'react';
import ReactDOM from 'react-dom';
import ReactOutlineManager from 'react-outline-manager';
import Router from './Router';
import { LanguageContextProvider } from './utils/LanguageContext';
import content from './constants/content';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';

import './styles/base.css';


const TRACKING_ID = "G-DBF4F82NYN"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

ReactDOM.render(
  <LanguageContextProvider value={{ content: content.en }}>
    <React.StrictMode>
      <ReactOutlineManager>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </ReactOutlineManager>
    </React.StrictMode>
  </LanguageContextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
