import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { TextImageSecond } from '../../templates';
import './styles.css'
// import '../../../assets/images/'
type Props = {
	content: {
		landingPage: {
			new: {
				content:string[];
				heading: string;
				buttonLabel: string;
				href:string
				subheading:string
			};
		};
	};
};

/**
 * Description
 *
 * @typedef {Object} Props
 *
 * @augments {Component<Props>}
 */
const NewView: React.FC<Props> = (props: Props) => {
	const { buttonLabel, content, heading, href, subheading} =
		props.content.landingPage.new;
	return <TextImageSecond {...{ buttonLabel, content, heading, href, subheading}} className="NewView" reversed/>;
};

export default withLanguageContext(NewView);
