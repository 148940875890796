import React, { Component } from 'react';
import { genClassName } from '../../../utils/helpers';
import './styles.css';
import { PlayButton, YouTubePlayer } from '../../atoms';

type Props = {
    heading?: string;
    content?:string;
    subContent?: string
    heading1?: string;
    heading2?: string;
    videoId: string;
    className: string;
};

type State = {
    isVideoInitiated: boolean;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class VideoSection extends Component<Props, State> {
    state = {
        isVideoInitiated: false,
    };

    handleClick = () => {
        this.setState({ isVideoInitiated: true });
    }

    render () {
        const { heading, content, videoId, className, subContent } = this.props;

        return (
            <section className='VideoSection'>
                { heading ? <h1 className='VideoSection-heading'>{heading}</h1> : '' }
                { content ? <p>{content}</p> : '' }
                {subContent && <p>{subContent}</p>}
                <div className={genClassName('VideoSection-wrap', className)}>{this.state.isVideoInitiated
                    ? <YouTubePlayer className='VideoSection-player' videoId={videoId} />
                    : <h1 className='VideoSection-play-wrap'>
                        <span></span>
                        <span></span>
                        <span><PlayButton onClick={this.handleClick} /></span>
                    </h1>
                }</div>
            </section>
        );
    }
}
