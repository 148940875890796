import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import CoronaInfo from '../../templates/CoronaInfo';

type Props = {
    content: {
        landingPage: {
            coronaInfo: {
                heading: string;
                buttonLabel: string;
                content: string;
				signUpLink: string;
				signUpText: string;
				taggisLink: string;
				taggisText: string;
            }
        }
    }
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const CoronaInfoView: React.FC<Props> = (props: Props) => {

	const {
		buttonLabel,
		content,
		heading,
		signUpLink,
		signUpText,
		taggisLink,
		taggisText,
	} = props.content.landingPage.coronaInfo;

	return (
		<CoronaInfo
			{...{
				buttonLabel,
				content,
				heading,
				signUpLink,
				signUpText,
				taggisLink,
				taggisText,
			}}
		/>
	);

};

export default withLanguageContext(CoronaInfoView);
