import React, { Component, Fragment } from 'react';

import './styles.css';

type Props = {
	heading: string,
	subheading: string
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} heading
 * @prop {String} subheading
 *
 * @augments {Component<Props>}
 */

export default class FaqHeading extends Component<Props> {
	render () {
		const { heading, subheading } = this.props;
		return (
			<Fragment>
				<div className='FaqSubHeading'>{subheading}</div>
				<h2 className='FaqHeading'>{heading}</h2>
			</Fragment>
		);
	}
}
