import React, { Component } from 'react';
import classNames from "classnames";

import AccordionTitle from '../../atoms/AccordionTitle';
import AccordionButton from '../../atoms/AccordionButton';
import VideoBox from '../VideoBox/VideoBox';
import SimpleMarkdown from '../../atoms/SimpleMarkdown';
import { Answer } from '../../../utils/types';

import './styles.css';

type Props = {
	key: number,
	question: string,
	answer: Answer,
};

type State = {
	isOpen: boolean;
};

/**
 * AccordionItem
 *
 * @typedef {Object} Props
 * @prop {string}  question
 * @prop {Object}  answer
 *
 * @augments {Component<Props>}
 */

 export default class AccordionItem extends Component<Props, State> {
	state = {
		isOpen: false,
	};

	/**
		* @type {number}
	*/
	timeout: number = 0;

	accordionAnswerRef = React.createRef<HTMLDivElement>();

	handleToggle = () => {
		this.setState(
			{
				isOpen: !this.state.isOpen,
			},
			() => {
				if (!this.accordionAnswerRef.current) return;

				window.clearTimeout(this.timeout); // Clear timeout in case user click faster than "animationDuration"
				const contentWrap = this.accordionAnswerRef.current;
				if (this.state.isOpen) {
					contentWrap.style.height = `${contentWrap.scrollHeight}px`;
					this.timeout = window.setTimeout(() => {
						contentWrap.style.height = 'auto';
					}, 3000);
				} else {
					contentWrap.style.height = `${contentWrap.scrollHeight}px`;
					this.timeout = window.setTimeout(() => {
						contentWrap.style.height = '0px';
					}, 0);
				}
			}
		);
	};


	genClassName = (state: State) =>
		classNames('AccordionItemAnswer', {
			AccordionItemAnswerOpen: state.isOpen,
		});

	render () {
		return (
			<div
				className={`AccordionItem ${
					this.state.isOpen ? 'AccordionItemOpen' : ''
				}`}
			>
				<div
					data-testid="AccordionItemWrapId"
					className={`AccordionItemWrap ${
						this.state.isOpen ? 'AccordionItemWrapOpen' : ''
					}`}
					onClick={this.handleToggle}
				>
					<AccordionTitle
						isOpen={this.state.isOpen}
						content={this.props.question}
					/>
					<AccordionButton isOpen={this.state.isOpen} />
				</div>
				<div
					ref={this.accordionAnswerRef}
					className={this.genClassName(this.state)}
				>
					<div className='listStyle'>
						<SimpleMarkdown extended={true}>
							{this.props.answer.text}
						</SimpleMarkdown>
					</div>

					{this.props.answer.videos && this.props.answer.videos.map((videoId) => (
						<div className='videoBoxWrap' key={videoId}>
							<VideoBox
								videoId={videoId}
							/>
						</div>
					)) }

				</div>
			</div>
		);
	}
}
