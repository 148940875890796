import React from 'react';
import { TextOnly } from '../../templates';
import './styles.css';
import { withLanguageContext } from '../../../utils/LanguageContext';

type Props = {
    content:{
        landingPage: {
            aviationEdocs: {
                heading: string;
                content: string;
            };
        };
    };
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const AviationEdocsView: React.FC<Props> = (props) => {
    const {
        heading,
        content,
    } = props.content.landingPage.aviationEdocs;
    return (
        <TextOnly
            {...{
                heading,
                content
            }}
            className='AviationEdocsView'
        />
    );

};

export default withLanguageContext(AviationEdocsView);
