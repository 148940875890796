import React, { Component } from 'react';
import classNames from 'classnames';
import { genClassName } from '../../../utils/helpers';
import { PaddedSection } from '../../atoms';
import ContentConverter from '../../../utils/contentConverter';
import Markdown from 'markdown-to-jsx';
import './styles.css';

type Props = {
    imageToRight: boolean | undefined;
    imageClassName: string;
    heading: string;
    content: string;
    className?: string;
    buttonLabel: string;
    href: string;
    target: string;
    download?: boolean
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class TextImage extends Component<Props, {}> {

    getHeading = (heading: string) => new ContentConverter(heading).nlToBr().getReactHtml();

    genMainClassName = () => classNames('TextImage', this.props.className, {
        imageToRight: this.props.imageToRight
    });

    render () {
        const { content, heading, buttonLabel, href, target, download = false } = this.props;
        //Providing filename as a href here
        const fileHref =  download ? require(`../../../assets/files/${href}`) : href

        return (
            <PaddedSection className={this.genMainClassName()}>
                <div className='TextImage-text'>
                    <h1 dangerouslySetInnerHTML={this.getHeading(heading)} />
                    <Markdown>{ content }</Markdown>
                    { target && buttonLabel && href ? 
                    <a
                        className='Button Button-arrow-next' 
                        href={ fileHref }
                        target={ target }
                        download={download}
                    >{ buttonLabel }</a> : null }
                </div>
                <div className={genClassName('TextImage-image', this.props.imageClassName)} />
            </PaddedSection>
        );
    }

}
