import React from 'react';
import { usePagination, DOTS } from './usePagination';
import classnames from 'classnames';

import './styles.css';

type Props = {
	className: string,
	currentPage: number,
	totalCount: number,
	pageSize: number,
	onPageChange: (page: number) => void
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */



const Pagination: React.FC<Props> = (props: Props) => {
	const { onPageChange, totalCount, currentPage, pageSize, className } = props;

	const paginationRange: any = usePagination({
		currentPage,
		totalCount,
		pageSize
	});

	const lastPage = paginationRange[paginationRange.length - 1];

	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		if (currentPage === lastPage) return;
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		if (currentPage === 1) return;
		onPageChange(currentPage - 1);
	};


	return (
		<ul
		  className={classnames('pagination-container', { [className]: className })}
		>
		  <li
			className={classnames('pagination-item', {
			  disabled: currentPage === 1
			})}
			onClick={onPrevious}
		  >
			<div className="arrow left" />
		  </li>
		  {paginationRange.map((pageNumber: number | string)  => {
			if (pageNumber === DOTS) {
			  return <li className="pagination-item dots">&#8230;</li>;
			}

			return (
			  <li
				key={pageNumber.toString()}
				className={classnames('pagination-item', {
				  selected: pageNumber === currentPage
				})}
				onClick={() => onPageChange(pageNumber as number)}
			  >
				{pageNumber}
			  </li>
			);
		  })}
		  <li
			className={classnames('pagination-item', {
			  disabled: currentPage === lastPage
			})}
			onClick={onNext}
		  >
			<div className="arrow right" />
		  </li>
		</ul>
	);

};

export default Pagination;
