import React, { Fragment, PureComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from "react-router-dom";

// const ALLOWED_TYPES: NodeType[] = ['link', 'linkReference', 'strong', 'paragraph'];
// const EXTENDED_ALLOWED_TYPES: NodeType[] = [...ALLOWED_TYPES, 'heading', 'list', 'listItem'];

type Props = {
    children: string;
    extended?: boolean;
};

/**
 * Description
 *
 * @typedef {Object} Props
 *
 * @augments {Component<Props>}
 */
export default class SimpleMarkdown extends PureComponent<Props, {}> {
  mdContentRenderer = ({ children }: Props) => <Fragment>{children}</Fragment>;
  mdLinkRenderer = ({ children, href }: { children: string; href: string }) => {
    if (href.includes('mailto') || href.includes('http')) {
      return (
        <a href={href} target='_blank' rel='noopener noreferrer'>
          {children}
        </a>
      );
    }
    return <Link to={href}>{children}</Link>;
  };

  // getAllowedTypes = (extended: boolean): NodeType[] => extended ? EXTENDED_ALLOWED_TYPES : ALLOWED_TYPES;

  genContent = ({ children }: Props) => {
    return <ReactMarkdown renderers={{ link: this.mdLinkRenderer, root: this.mdContentRenderer }} source={children} />;
  };

  render() {
    return this.props.children ? this.genContent(this.props) : null;
  }
}
