import React, { useState } from "react";
import ContentConverter from "../../../utils/contentConverter";
import { PaddedSection, PlayButton, YouTubePlayer } from "../../atoms";
import { Modal } from "../../molecules";
import { Link } from 'react-router-dom';

import "./styles.css";
import Markdown from "markdown-to-jsx";
import { genClassName } from "../../../utils/helpers";

type Props = {
  content: string | string[];
  heading: string;
  videoId: string;
  image: {
    src: string;
    alt: string;
  };
  className?: string;
  reversed?: boolean
  href?: string;
	buttonLabel?: string;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const VideoBoxWithText: React.FC<Props> = ({
  content,
  heading,
  videoId,
  image,
  reversed,
  href,
  buttonLabel,
  className
}: Props) => {
  const [videoOpen, setVideoOpen] = useState(false);

  const closeModal = () => setVideoOpen(false);

  const showModal = () => setVideoOpen(true);

  const getContent = (content: string) =>
  new ContentConverter(content).convertLinks().nlToBr().getHtml();

  const imageUrl = require(`../../../assets/images/${image.src}`);

  return (
    <PaddedSection className={
      genClassName('VideoBoxWithText', {
        [className || '']: className,
        reversed,
      })}>
      <div className="innerWrap">
        <div className="contentWrap">
          <h3 className="heading">{heading}</h3>
          <div className="content">
          {Array.isArray(content) ? (
						content.map((item: string, index: number) => (
							<p key={index}>
								<Markdown
									options={{
										overrides: {
											Link: {
												component: Link,
											},
										},
									}}
								>
									{getContent(item)}
								</Markdown>
							</p>
						))
					) : (
						<p>
							<Markdown
								options={{
									overrides: {
										Link: {
											component: Link,
										},
									},
								}}
							>
								{getContent(content)}
							</Markdown>
						</p>
					)}
          </div>
          {href && buttonLabel ? (
						<a
							className='Button'
							href={href}
						>
							<span>{buttonLabel}</span>
						</a>
					) : null}
        </div>
        <div className="videoWrap">
          <div className="video">
            <span className="VideoButton">
              <PlayButton onClick={showModal} />
            </span>
            <img src={imageUrl} alt={image.alt} />
          </div>
        </div>
      </div>
      <Modal onClose={closeModal} show={!!videoOpen}>
        {videoId && (
          <YouTubePlayer className="youtube-video" videoId={videoId} />
        )}
      </Modal>
    </PaddedSection>
  );
};

export default VideoBoxWithText;
