import React, { Component } from 'react';
import './styles.css';
import YouTubePlayer from '../../atoms/YouTubePlayer';
import PlayButton from '../../atoms/PlayButton';

type Props = {
	videoId: string,
};

type State = {
	show: boolean,
};

/**
 * VideoBox
 *
 * @typedef {Object} Props
 * @prop {string}  videoId
 * @prop {Boolean}  isToggled
 *
 * @augments {Component<Props>}
 */

export default class VideoBox extends Component<Props, State> {
	state = { show: false };
	
	handleClick = () => {
		this.setState({ show: true });
	};

	render () {
		const { videoId } = this.props;

		return (
			<div className='videoBox'>
				{this.state.show ? (
					<YouTubePlayer
						className='youtube-video'
						videoId={videoId}
					/>
				) : (
					<div>
						<picture>
							<img
								src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
								className='videoBoxImg'
								alt={videoId}
							/>
						</picture>
						<span className='videoButton'>
							<PlayButton onClick={this.handleClick} />
						</span>
					</div>
				)}
			</div>
		);
	}
}
