import React, { Fragment, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import LandingPage from './LandingPage';
// import FaqPage from './FaqPage';
import NewsPage from './NewsPage';
import TermsDialogsView from './components/views/TermsDialogsView';
import { AppNotificationView } from './components/views';
import SolutionsPage from './SolutionsPage';
import AboutAndPartnersPage from './AboutAndPartnersPage';
import BookMeetingPage from './BookMeetingPage';
import SocialMediaPage from './SocialMediaPage';
import BrochurePage from './BrochurePage';
import PricingPage from './PricingPage';
import ReactGA from 'react-ga4';
import { capitalize } from './utils/helpers';

function usePageViews() {
    let location = useLocation();
    useEffect(() => {
        const { pathname } = location;
        const pathNameArr = pathname.split("/");
        const pathNameLast = pathNameArr[pathNameArr.length - 1];
        const pathName = capitalize(pathNameLast.split("-").join(" "));
        ReactGA.send(window.location.pathname + window.location.search);
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname + window.location.search,
            title: pathName
          });
    }, [location]);
  }

const Router = () => {
    usePageViews();

    return <Fragment>
        <Switch>
            {/* <Route
                path='/faq'
                render={({ history }) => {
                    if (history.location.hash) {
                        return (
                            <Redirect
                                to={`/${history.location.hash}`}
                            />
                        );
                    } else {
                        return <FaqPage />;
                    }
                }}
            /> */}
            <Route
                path='/news'
                render={({ history }) => {
                    if (history.location.hash) {
                        return (
                            <Redirect
                                to={`/${history.location.hash}`}
                            />
                        );
                    } else {
                        return <NewsPage />;
                    }
                }}
            />
            <Route
                path='/solutions'
                render={({ history }) => {
                    if (history.location.hash) {
                        return (
                            <Redirect
                                to={`/${history.location.hash}`}
                            />
                        );
                    } else {
                        return <SolutionsPage />;
                    }
                }}
            />
            <Route
                path='/partners'
                render={({ history }) => {
                    if (history.location.hash) {
                        return (
                            <Redirect
                                to={`/${history.location.hash}`}
                            />
                        );
                    } else {
                        return <AboutAndPartnersPage />;
                    }
                }}
            />
            <Route
                path='/book-meeting'
                render={({ history }) => {
                    if (history.location.hash) {
                        return (
                            <Redirect
                                to={`/${history.location.hash}`}
                            />
                        );
                    } else {
                        return <BookMeetingPage />;
                    }
                }}
            />
            <Route
                path='/social-media'
                render={({ history }) => {
                    if (history.location.hash) {
                        return (
                            <Redirect
                                to={`/${history.location.hash}`}
                            />
                        );
                    } else {
                        return <SocialMediaPage />;
                    }
                }}
            />
            <Route
                path='/brochure'
                render={({ history }) => {
                    if (history.location.hash) {
                        return (
                            <Redirect
                                to={`/${history.location.hash}`}
                            />
                        );
                    } else {
                        return <BrochurePage />;
                    }
                }}
            />
            <Route
                path='/pricing'
                render={({ history }) => {
                    if (history.location.hash) {
                        return (
                            <Redirect
                                to={`/${history.location.hash}`}
                            />
                        );
                    } else {
                        return <PricingPage />;
                    }
                }}
            />
            <Route exact={true} component={LandingPage} />
        </Switch>
        <Route path='*/(cookies|privacy|terms)' component={TermsDialogsView} />
        <AppNotificationView />
    </Fragment>
};

export default Router;
