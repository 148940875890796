import React, { Component } from 'react';
import classNames from 'classnames';

import './styles.css';

type Props = {
    right?: boolean;
    onClick: (event: React.SyntheticEvent) => void;
    className: string;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class PaginationButton extends Component<Props, {}> {

    genClassName = (props: Props) => classNames('PaginationButton', props.className, {
        left: !props.right,
        right: props.right,
    });

    render () {
        return (
            <button
                type='button'
                className={this.genClassName(this.props)}
                onClick={this.props.onClick}
                aria-label={this.props.right ? 'Next' : 'Previous'}
            />
        );
    }

}
