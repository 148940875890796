import React from 'react';

import './styles.css';
import { genClassName } from '../../../utils/helpers';
import { ArrowLink, Dialog } from '../../atoms';

type Props = {
    content: string;
    linkLabel: string;
    heading: string;
    onClose: () => void;
    error: boolean
    visible: boolean;
};

/**
 * Description
 *
 * @typedef {Object} Props
 *
 * @augments {Component<Props>}
 */
const FormDialog: React.FC<Props> = (props: Props) => {
    const { content, linkLabel, heading, onClose } = props;

    return (
        <Dialog className={genClassName('FormDialog', props.error ? 'error' : '')} {...props}>
            <div />
            <div>
                <h1>{heading}</h1>
                <p>{content}</p>
                <ArrowLink onClick={onClose} className='FormDialog-backLink'>{linkLabel}</ArrowLink>
            </div>
        </Dialog>
    );

};

export default FormDialog;
