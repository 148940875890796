import React, { Component } from 'react';
import { PaddedBlock } from './components/atoms';
import {
  HeaderView,
  FooterView,
  ContactUsView,
  GetInTouchView,
  DocBrowserWebPortal,
  IFlyThereVideoView,
  TaggisView,
  IreformAppView,
  FlightBriefingAppView,
} from './components/views';

class SolutionsPage extends Component {

  componentDidMount () {
      window.scrollTo(0, 0);
  }

  render() {
    return (
      <main>
        <HeaderView opaque={true} />
        <PaddedBlock padding={60} />
        <DocBrowserWebPortal />
        <IFlyThereVideoView />
        <IreformAppView />
        <FlightBriefingAppView />
        <TaggisView />
        <GetInTouchView />
        <ContactUsView />
        <FooterView />
      </main>
    );
  }
}

export default SolutionsPage;
