import React from 'react';

import './styles.css';
import { VideoBoxWithText } from '../../templates';
import { withLanguageContext } from '../../../utils/LanguageContext';

type Props = {
    content: {
        solutionsPage: {
            IReFormVideoView: {
				content: string;
				heading: string;
				videoId: string;
				image: {
					src: string;
					alt: string;
				}
            }
        }
    }
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const IReFormVideoView: React.FC<Props> = (props: Props) => {

	const {
		heading,
        content,
        videoId,
        image
    } = props.content.solutionsPage.IReFormVideoView;

    return (
        <VideoBoxWithText
			heading={heading}
			content={content}
			videoId={videoId}
			image={image}
		/>
    );

};

export default withLanguageContext(IReFormVideoView);
