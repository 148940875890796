/**
 * Class to convert string to HTML
 */
export default class ContentConverter {
    _content: string;

    /**
     * @param {String} content
     */
    constructor (content: string) {
        this._content = content;
    }

    get content (): string {
        return this._content;
    }

    /**
     * Return object prepared for `dangerouslySetInnerHTML`
     */
    getReactHtml () {
        return {
            __html: this._content,
        };
    }

    /**
     * Return HTML string
     */
    getHtml (): string {
        return this._content;
    }

    /**
     * Replace new line wit HTML break
     */
    nlToBr () {
        this._content = this._content.replace(/(?:\r\n|\r|\n)/g, '<br>');
        return this;
    }

    /**
     * Convert markdown link to HTML link
     */
    convertLinks () {
        const regexMdLinks = /\[([^[]+)\]([^[)]+)\)/gm;
        const matches = this._content.match(regexMdLinks);
        if (!matches) return this;
        /**
         * @param {String} mdLink
         */
        matches.forEach((mdLink: string) => {
            this._content = this._content.replace(mdLink, this.getHtmlLink(mdLink));
        });
        return this;
    }

    /**
     * Return HTML link from parsed from markdown link
     * @param {*} mdLink Markdown link
     */
    getHtmlLink (mdLink: string): string {
        const singleMatch: RegExp = /\[([^[]+)\]\((.*)\)/;
        const parsedLink: RegExpExecArray | null = singleMatch.exec(mdLink);

        if (!parsedLink) return '';

        const label = parsedLink[1];
        const href = parsedLink[2];
        if (label.includes('{R}')) return `<Link to="${href}">${label.replace('{R}', '')}</Link>`;
        return `<a target="_blank" href="${href}">${label}</a>`;
    }
}
