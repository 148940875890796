import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { LearnMore } from '../../templates';
import './styles.css';

type Props = {
  content: {
    landingPage: {
      downloadBrochure: {
        heading: string;
        buttonLabel: string;
        fileName: string;
      };
    };
  };
};

/**
 * Description
 *
 * @typedef {Object} Props

 *
 * @augments {Component<Props>}
 */
const LearnMoreView: React.FC<Props> = (props: Props) => {
  const { heading, buttonLabel, fileName } = props.content.landingPage.downloadBrochure;
  return (
    <div className='LearnMoreView'>
      <LearnMore {...{ heading, buttonLabel, fileName }} />
    </div>
  );
};

export default withLanguageContext(LearnMoreView);
