import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { TextImage } from '../../templates';

import './styles.css';

type Props = {
    content: {
        solutionsPage: {
            Taggis: {
                content: string;
                heading: string;
                imageToRight: boolean;
                buttonLabel: string;
                href: string;
                target: string;
            }
        }
    }
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const TaggisView: React.FC<Props> = (props: Props) => {
	const {
		content,
		heading,
		imageToRight,
		buttonLabel,
		href,
		target
	} = props.content.solutionsPage.Taggis;

	return (
		<TextImage
			{...{
				content,
				heading,
				imageToRight,
				buttonLabel,
				href,
				target,
			}}
			imageClassName='TaggisView'
			className='Taggis'
		/>
	);

};

export default withLanguageContext(TaggisView);
