import React, { Component } from 'react';
import { genClassName } from '../../../utils/helpers';
import { SimpleMarkdown } from '..';

import './styles.css';

type Props = {
	className: string;
	onClick: (notificationType: string) => void;
	children: string;
	buttonLabel: string;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class AppNotification extends Component<Props, {}> {

	handleClick = () => this.props.onClick && this.props.onClick('');

	render () {
		return (
			<div role='alert' className={genClassName('AppNotification', this.props.className)}>
				<SimpleMarkdown>{this.props.children}</SimpleMarkdown>
				<button onClick={this.handleClick} className='AppNotification-button'>
					{this.props.buttonLabel}
				</button>
			</div>
		);
	}

}
