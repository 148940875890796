import React, { useEffect } from "react";

import "./styles.css";

type Props = {
  show: boolean
  onClose: () => void;
  children: React.ReactNode;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const Modal: React.FC<Props> = (props: Props) => {
  const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
    if (e.code === "Escape") {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (!props.show) return null;

  return (
    <div className="Modal">
      <div className={`modal ${props.show ? "visible" : ""}`} onClick={props.onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-body">
            <div className="close" onClick={props.onClose} />
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
