import React from 'react';
import { genClassName } from '../../../utils/helpers';
import { timePickerHours } from '../../../utils/timePickerHours';

import './styles.css';

type Props = {
  value: string;
  onChange: (value: string) => void;
  minDate?: string;
  errorMessage?: string;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const TimePicker: React.FC<Props> = ({ onChange, value, errorMessage }: Props) => {
  return (
    <>
    {errorMessage && <span className='timePickerError'>{errorMessage}</span>}
      <div className={genClassName('TimePicker', {errorBorder : errorMessage})}>
        <div className='slotsContainer'>
          {timePickerHours.map((hour, index) => (
            <span
              key={index}
              className={genClassName('slot', { active: value === hour })}
              onClick={() => onChange(hour)}
            >
              {hour}
            </span>
          ))}
        </div>
      </div>
    </>
  );
};

export default TimePicker;
