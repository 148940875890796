import React, { Component } from 'react';

import './styles.css';

type Props = {
    count: number;
    index: number;
    onChange: (value: number) => void
};

/**
 * Description
 *
 * @typedef {Object} Props
 *
 * @augments {Component<Props>}
 */
export default class SlidePagination extends Component<Props, {}> {

    genPagination = (_item: string, index: number) => (
        <label key={index}>
            <input
                checked={index === this.props.index}
                name='pagination'
                onChange={this.handleChange}
                type='radio'
                value={index}
            />
            <span />
        </label>
    );

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => this.props.onChange && this.props.onChange(+event.target.value);

    render () {
        return (
            <form className='SlidePagination'>
                {Array(this.props.count).fill('').map(this.genPagination)}
            </form>
        );
    }

}
