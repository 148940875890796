import React, { Component } from "react";

import { NewsInfoBlockType } from "../../../utils/types";
import { SimpleMarkdown } from "../../atoms";
import PlayButton from "../../atoms/PlayButton";

import "./styles.css";

type Props = {
  key: number;
  infoBlock: NewsInfoBlockType;
  expandedItemId: number | null;
  setVideoId: (videoId: string) => void;
  setExpandedItemId: (itemId: number | null) => void;
};

type State = {
  hasReadMore: boolean;
}

const READ_MORE_HEIGHT = 505;

export default class NewInfoBlock extends Component<Props, State> {

  timeout: number = 0;

  newInfoBlockRef = React.createRef<HTMLDivElement>();

  // @ts-ignore
  resizeObserver?: ResizeObserver;

  constructor(props: Props) {
    super(props);

    this.state = {
      hasReadMore: false,

    }
  }

  componentDidMount() {
    const newInfoBlockRefCurrent = this.newInfoBlockRef.current;

    if(!newInfoBlockRefCurrent) return;

    // @ts-ignore
    this.resizeObserver = new ResizeObserver(() => {
      if ( !this.state.hasReadMore && newInfoBlockRefCurrent.scrollHeight - 20 > READ_MORE_HEIGHT) {
        this.setState({hasReadMore: true});
      } else if (this.state.hasReadMore && newInfoBlockRefCurrent.scrollHeight - 20 < READ_MORE_HEIGHT){
        this.setState({hasReadMore: false});
      }
    });

    this.resizeObserver.observe(newInfoBlockRefCurrent);
  }

  componentWillUnmount(){
    this.resizeObserver?.disconnect();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!this.newInfoBlockRef.current || !this.state.hasReadMore) return;

    window.clearTimeout(this.timeout); // Clear timeout in case user click faster than "animationDuration"

    const contentWrap = this.newInfoBlockRef.current;
    if (nextProps.expandedItemId === this.props.infoBlock.id) {
      contentWrap.style.height = `${contentWrap.scrollHeight}px`;
      this.timeout = window.setTimeout(() => {
        contentWrap.style.height = "auto";
      }, 3000);
    } else {
      contentWrap.style.height = `${contentWrap.scrollHeight}px`;
      this.timeout = window.setTimeout(() => {
        contentWrap.style.height = "505px";
      }, 0);
    }
  }

  handleExpandingText = (itemId: number) => {
    this.props.setExpandedItemId(
      this.props.expandedItemId === itemId ? null : itemId
    );
  };

  render() {
    const {
      infoBlock: {id, attributes: { media, title, content, createdData, categories, youTubeUrl, mediaObjectFit }},
      setVideoId,
      expandedItemId
    } = this.props;

    return (
      <div
        key={id}
        ref={this.newInfoBlockRef}
        className={`NewInfoBlock ${
          expandedItemId === id ? "NewInfoBlockExpanded" : ""
        }`}
      >
        <div className="MediaContent">
          {media?.attributes?.url && (
            <img
              className="ImageContainer"
              src={media.attributes.url}
              style={{ objectFit: mediaObjectFit}}
              alt={media.attributes.alternativeText}
            />
          )}
          {youTubeUrl && (
            <div className="VideoContainer">
              <span className="VideoButton">
                <PlayButton onClick={() => setVideoId(youTubeUrl)} />
              </span>
              <img
                className="ScreenContainer"
                src={`https://img.youtube.com/vi/${youTubeUrl}/maxresdefault.jpg`}
                alt={"video"}
              />
            </div>
          )}
        </div>
        <div className="DescriptionContainer">
          <div className="CategoryBlock">
            <p className="CategoryValue">
              {categories?.map((category: any) => category).join(", ")}
            </p>
            <div className="CreatedDate">{createdData}</div>
          </div>
          <div className="DescriptionTitle">
            {title}
          </div>
          <div className="ReadMoreText"><SimpleMarkdown>{content}</SimpleMarkdown></div>
          {
            this.state.hasReadMore &&
            <label
              className="ReadMore"
              onClick={() => this.handleExpandingText(id)}
            >
              {expandedItemId === id ? "Read Less" : "Read more"}
            </label>
          }
        </div>
      </div>
    );
  }
}
