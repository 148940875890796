import React, { Component } from 'react';
import { PaddedSection } from '../../atoms';
import ContentConverter from '../../../utils/contentConverter';
import Markdown from 'markdown-to-jsx';
import { Link } from 'react-router-dom';

import './styles.css';
import { genClassName } from '../../../utils/helpers';
import { ImageType } from '../../../utils/types';
import { BrandIcons } from '../../molecules';

type Props = {
	content: string | string[];
	subheading?: string;
	className: string;
	subHeadingStyle?: "blue" | "grey";
	heading?: string;
	headingFirst?: boolean;
	href?: string;
	buttonLabel?: string;
	reversed?: boolean;
	underButtonText?: string
	brandImages?: ImageType[]

};

/**
 * A template for landing page.
 *
 * @typedef {Object} Props
 *
 * @augments {Component<Props>}
 */
export default class TextImageSecond extends Component<Props, {}> {
	getContent = (content: string) =>
		new ContentConverter(content).convertLinks().nlToBr().getHtml();

	render() {
		const {
			buttonLabel,
			content,
			className,
			heading,
			headingFirst,
			href,
			subheading,
			subHeadingStyle = "blue",
			reversed,
			underButtonText,
			brandImages,
		} = this.props;

		return (
			<PaddedSection
				className={genClassName('TextImageSecond', {
					[className]: className,
					TextImageSecond_reversed: reversed,
				})}
			>
				<div
					className={genClassName('TextImageSecond-image', {
						TextImageSecond_image_reversed: reversed,
					})}
				/>
				<div className='TextImageSecond-content'>
					{!!headingFirst && heading && <h1>{heading}</h1>}
					{subheading && <h2 className={subHeadingStyle}>{subheading}</h2>}
					{!headingFirst && heading && <h1>{heading}</h1>}
					{Array.isArray(content) ? (
						content.map((item: string, index: number) => (
							<p key={index}>
								<Markdown
									options={{
										overrides: {
											Link: {
												component: Link,
											},
										},
									}}
								>
									{this.getContent(item)}
								</Markdown>
							</p>
						))
					) : (
						<p>
							<Markdown
								options={{
									overrides: {
										Link: {
											component: Link,
										},
									},
								}}
							>
								{this.getContent(content)}
							</Markdown>
						</p>
					)}
					{brandImages?.length && <BrandIcons images={brandImages}/>}
					{href && buttonLabel ? (
						<a
							className='Button TextImageSecond-button '
							href={href}
						>
							<span>{buttonLabel}</span>
						</a>
					) : null}
					{underButtonText && <span className={'underButtonText'}>{underButtonText}</span> }
				</div>
			</PaddedSection>
		);
	}
}
