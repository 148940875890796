import React from 'react';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { BlueHeading } from '../../atoms';

import './styles.css';

type Props = {
  content: {
    landingPage: {
      blueHeadingText: string;
    };
  };
};
/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
const BlueHeadingView: React.FC<Props> = (props: Props) => {
  const { blueHeadingText } = props.content.landingPage;
  return <BlueHeading blueHeadingText={blueHeadingText} />;
};

export default withLanguageContext(BlueHeadingView);
