import React from 'react';

import './styles.css';

type Props = {
  blueHeadingText: string;
};

/**
 * Description
 *
 * @typedef {Object} Props
 *
 * @augments {Component<Props>}
 */
const BlueHeading: React.FC<Props> = ({ blueHeadingText }: Props) => {
  return (
      <h2 className={'blueHeading'}>{blueHeadingText}</h2>
  );
};

export default BlueHeading;
