import React, { Component, createRef } from 'react';
import { HeroView,
    WhatWeDoView,
    HeaderView,
    FooterView,
    PortfolioView,
    LearnMoreView,
    ContactUsView,
    GetInTouchView,
    FreeTrialView,
    QuotesView,
    DidYouKnowView,
    ProductSuiteVideoView,
    AllToolsInOneSpaceView,
    OurPricingView,
    IFlyThereVideoView,
    IReFormVideoView,
    OurPartnersView,
} from './components/views';
import { HashAnchor } from './components/atoms';
import OurGrowingFleetView from './components/views/OurGrowingFleetView';
import BlueHeadingView from './components/views/BlueHeadingView';

const SUPPORTS_INTERSECTION_OBSERVER = typeof IntersectionObserver !== 'undefined';
const THRESHOLD = 0.95;

class LandingPage extends Component {
    io: IntersectionObserver | undefined;

    state = {
        showOpaqueHeader: false
    };

    heroRef = createRef<HTMLElement>();

    componentDidMount () {
        this.setupIntersectionObserver();
    }

    componentWillUnmount () {
        if (this.io) this.io.disconnect();
    }

    handleThresholdMatch (entries: IntersectionObserverEntry[]) {
        const [{ intersectionRatio }] = entries;
        this.setState({
            showOpaqueHeader: intersectionRatio <= THRESHOLD,
        });
    }

    setupIntersectionObserver = async () => {
        if (!SUPPORTS_INTERSECTION_OBSERVER) await import('intersection-observer');
        this.io = new IntersectionObserver(this.handleThresholdMatch.bind(this), { threshold: [THRESHOLD] });
        if (this.heroRef.current) this.io.observe(this.heroRef.current);
    }

    render() {
        return (
            <main>
                <HeaderView opaque={this.state.showOpaqueHeader} />
                <HeroView forwardedRef={this.heroRef} />
                <BlueHeadingView />
                <WhatWeDoView />
                <ProductSuiteVideoView />
                <PortfolioView />
                <IFlyThereVideoView />
                <IReFormVideoView />
                <AllToolsInOneSpaceView />
                <HashAnchor id="free-trial" />
                <FreeTrialView />
                <OurPricingView />
                <OurPartnersView />
                {/* <WingDoxLearnMoreView/> */}
                <OurGrowingFleetView />
                <QuotesView />
                {/* <HowOurPricingWorksView /> */}
                {/* <WhoWeAreView /> */}
                {/* <NewView/> */}
                <DidYouKnowView />
                <LearnMoreView />
                <HashAnchor id="contact" />
                <GetInTouchView />
                <ContactUsView />
                <FooterView />
				{/* <CoronaInfoView /> */}
            </main>
        );
    }
}

export default LandingPage;
