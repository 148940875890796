import React from 'react';
import { TextImageThird } from '../../templates';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { ImageType } from '../../../utils/types';

import './styles.css';

type Props = {
    content: {
        landingPage: {
            ourPartners: {
                heading: string;
                content: string;
                images: ImageType[];
                buttonLabel?: string;
                href?: string;
            }
        }
    }
};

/**
 * Description
 *
 * @typedef {Object} Props
 *
 * @augments {Component<Props>}
 */
const OurPartnersView: React.FC<Props> = (props) => {

    const {
        heading,
        content,
        images,
        buttonLabel,
        href,
    } = props.content.landingPage.ourPartners;

    return (
        <TextImageThird
            {...{
                heading,
                content,
                images,
                buttonLabel,
                href,
            }}
            className='ourPartnersView'
        />
    );

};

export default withLanguageContext(OurPartnersView);
