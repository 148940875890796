import React, { Component, createRef } from 'react';
import { genClassName } from '../../../utils/helpers';
import './styles.css';

type Props = {
    children: React.ReactNode;
    className: string;
    visible: boolean;
    onClose: () => void;
};

const IS_TEST_ENV = process.env.NODE_ENV === 'test';

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class Dialog extends Component<Props, {}> {

    ref = createRef<HTMLDialogElement>();

    static registerDialogPolyfill = async (dialog: HTMLDialogElement) => {
        const dialogPolyfill = await import('dialog-polyfill');
        dialogPolyfill.default.registerDialog(dialog);
    };

    async componentDidMount () {
        if (IS_TEST_ENV) return;

        if (!window.HTMLDialogElement && this.ref.current) await Dialog.registerDialogPolyfill(this.ref.current);
        if (this.props.visible) this.toggleDialog(true);

        if (this.ref.current)
            this.ref.current.addEventListener('close', this.handleDialogClose);
    }

    componentDidUpdate ({ visible }: Props) {
        if (this.props.visible === visible) return;
        this.toggleDialog(this.props.visible);
    }

    componentWillUnmount () {
        this.toggleBodyOverflow(true);
        if (this.ref.current)
            this.ref.current.removeEventListener('close', this.handleDialogClose);
    }


    handleDialogClose = () => {
        this.toggleBodyOverflow(true);
        if (this.props.onClose) this.props.onClose();
    }

    toggleDialog = (shouldShow: boolean) => {
        if (shouldShow) {
            this.toggleBodyOverflow(false);
            if (this.ref.current)
                this.ref.current.showModal();
        } else {
            if (this.ref.current)
                this.ref.current.close();
        }
    }

    toggleBodyOverflow = (shouldShow: boolean) => document.body.style.overflowY = shouldShow ? '' : 'hidden';

    render () {
        return (
            <dialog ref={this.ref} className={genClassName('Dialog', this.props.className)}>
                {this.props.children}
            </dialog>
        );
    }

}
