import React, { Component } from 'react';
import { TextImage } from '../../templates';
import './styles.css';
import { withLanguageContext } from '../../../utils/LanguageContext';

type Props = {
    content: {
        solutionsPage: {
            docBrowserWeb: {
                content: string;
                heading: string;
                imageToRight: boolean;
                buttonLabel: string;
                href: string;
                target: string;
            }
        }
    }
};


/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
class DocBrowserWebPortal extends Component<Props, {}> {
    render () {
        const {
            content,
            heading,
            imageToRight,
            buttonLabel,
            href,
            target,
        } = this.props.content.solutionsPage.docBrowserWeb;

        return (
            <TextImage
                {...{
                    content,
                    heading,
                    imageToRight,
                    buttonLabel,
                    href,
                    target,
                }}
                imageClassName='DocBrowserWebPortalView'
                className='DocBrowser'
            />
        );
    }
}

export default withLanguageContext(DocBrowserWebPortal);
