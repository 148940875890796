import React, { Component, Fragment } from 'react';
import FormDialog from '../../molecules/FormDialog';
import { ContactFormType, FormDataType } from '../../../utils/types';

import './styles.css';
import formToJson from '../../../utils/formToJson';

type Props = {
    backLabel: string;
    serverErrorContent: string;
    serverErrorMessage: string;
    serverSuccessContent: string;
    serverSuccessMessage: string;
    submitHandler: (data: FormDataType) => Promise<{ json: JSON; success: boolean; } | { success: boolean; json?: undefined; }>;
    onSuccess: () => void;
    render: (state: ContactFormType) => React.ReactNode;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class Form extends Component<Props, ContactFormType> {

    state = {
        errors: [],
        isServerError: false,
        isDialogVisible: false,
        isSubmitting: false,
    };

    handleDialogClose = () => {
        this.setState({ isDialogVisible: false });
        this.props.onSuccess();
    }

    handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.setState({ isSubmitting: true });

        // Recipients receive a JSON representation of the form data.
        const data = formToJson(event.currentTarget);

        try {
            const response = await this.props.submitHandler(data);
            const { success } = response;
            if (success) {
                this.setState({ isServerError: false });
            }
        } catch (error) {
            console.log(error); /* eslint-disable-line no-console */
            this.setState({ isServerError: true });
        }
        this.setState({ isSubmitting: false, isDialogVisible: true });
    }

    render () {
        const {
            backLabel,
            serverErrorContent,
            serverErrorMessage,
            serverSuccessContent,
            serverSuccessMessage,
            render,
        } = this.props;
        const { isServerError, isDialogVisible, ...state } = this.state;

        return (
            <Fragment>
                <FormDialog
                    content={isServerError ? serverErrorContent : serverSuccessContent}
                    error={isServerError}
                    heading={isServerError ? serverErrorMessage : serverSuccessMessage}
                    linkLabel={backLabel}
                    onClose={this.handleDialogClose}
                    visible={isDialogVisible}
                />
                <form onSubmit={this.handleSubmit}>
                    {
                        render(state)
                    }
                </form>
            </Fragment>
        );
    }

}
