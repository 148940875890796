import React, { Fragment, useState, useMemo, useEffect } from 'react';
import { Modal, Select, NewInfoBlock, Pagination } from '../../molecules';
import { NewsInfoBlockType, NewsPostType, OptionType, PostsPaginationType } from '../../../utils/types';
import { useIsMobile } from '../../../hooks/useIsMobile';

import './styles.css';
import { YouTubePlayer } from '../../atoms';
import { getNewsPosts, getNewsPostsCategories } from '../../../sevices/DOCBrowserApi';

const initialOptions = [{ key: '', value: 'All categories' }];

const categoriesPaginationSize = 100;

const initialPagination = {
  page: 1,
  pageSize: 6,
  pageCount: 0,
  total: 0,
};

const categoryRelationLink = 'db_news_categories';

type Props = {
  content: {
    newsPage: {
      heading: {
        title: string;
        subTitle: string;
      };
    };
  };
};

type TwoColumnNews = {
  firstColumn: NewsInfoBlockType[];
  secondColumn: NewsInfoBlockType[];
};

const formatNews = (news: NewsInfoBlockType[]): TwoColumnNews => {
  const twoColumnNews: TwoColumnNews = {
    firstColumn: [],
    secondColumn: [],
  };

  news.forEach((newsItem, index) => {
    if (index % 2 !== 0) twoColumnNews.secondColumn.push(newsItem);
    else twoColumnNews.firstColumn.push(newsItem);
  });

  return twoColumnNews;
};

const NewsPage: React.FC<Props> = (props: Props) => {
  const [newsCollection, setNewsCollection] = useState<NewsPostType[]>([]);
  const [expandedItemId, setExpandedItemId] = useState<number | null>(null);
  const [videoId, setVideoId] = useState<string | null>(null);
  const [newsPageError, setNewsPageError] = useState<boolean>(false);
  const [categories, setCategories] = useState<OptionType[]>(initialOptions);
  const [currentCategory, setCurrentCategory] = useState<string>('');
  const [pagination, setPagination] = useState<PostsPaginationType>(initialPagination);

  const isMobile = useIsMobile();

  const getParams = () => {
    const categoryParam = currentCategory
      ? // eslint-disable-next-line no-useless-escape
        `filters\[${categoryRelationLink}\][newsCategory][$eq]=${currentCategory}`
      : '';
    const paginationParams = `pagination[page]=${pagination.page}&pagination[pageSize]=${initialPagination.pageSize}`;
    return (
      (categoryParam ? `${categoryParam}&${paginationParams}` : paginationParams) +
      '&populate=*&sort[0]=order:ASC&sort[0]=createdData:DESC'
    );
  };

  const loadNewsCategories = async () => {
    try {
      const params = `?pagination[pageSize]=${categoriesPaginationSize}`;
      const res = await getNewsPostsCategories(params);
      if (res?.json) {
        const categories = res.json.data.map((category) => ({
          key: category.attributes.newsCategory,
          value: category.attributes.newsCategory,
        }));
        setCategories([...initialOptions, ...categories]);
      }
    } catch (error) {
      setNewsPageError(!!error);
    }
  };

  const loadNews = async () => {
    const params = `?${getParams()}`;
    try {
      const res = await getNewsPosts(params);
      if (res?.json?.data) {
        setNewsCollection(res.json.data);
      }
      if (res?.json?.meta) {
        setPagination(res.json.meta.pagination);
      }
    } catch (error) {
      setNewsPageError(!!error);
    }
  };

  const handleChangeCategory = (option: OptionType) => {
    setCurrentCategory(option.key);
    setPagination({ ...pagination, page: 1 });
  };

  const currentNewsCollectionData = useMemo(() => {
    const collection = newsCollection
      .map((post) => ({
        ...post,
        attributes: {
          ...post.attributes,
          categories: post.attributes.db_news_categories.data
            ? post.attributes.db_news_categories.data.map((category) => category.attributes.newsCategory)
            : [],
          media: post.attributes?.media?.data,
        },
      }))

    return isMobile ? collection : formatNews(collection);
  }, [newsCollection, isMobile]);

  const closeModal = () => setVideoId(null);

  useEffect(() => {
    loadNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory, pagination.page]);

  useEffect(() => {
    loadNewsCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderNewsContent = () => {

    if (newsPageError) {
      return <h2 className={'postsError'}>News are on the way</h2>;
    }

    return Array.isArray(currentNewsCollectionData) ? (
      currentNewsCollectionData.map((infoBlock) => (
        <NewInfoBlock
          key={infoBlock.id}
          expandedItemId={expandedItemId}
          infoBlock={infoBlock}
          setVideoId={setVideoId}
          setExpandedItemId={setExpandedItemId}
        />
      ))
    ) : (
      <>
        <div>
          {currentNewsCollectionData.firstColumn.map((infoBlock) => (
            <NewInfoBlock
              key={infoBlock.id}
              expandedItemId={expandedItemId}
              infoBlock={infoBlock}
              setVideoId={setVideoId}
              setExpandedItemId={setExpandedItemId}
            />
          ))}
        </div>
        <div>
          {currentNewsCollectionData.secondColumn.map((infoBlock) => (
            <NewInfoBlock
              key={infoBlock.id}
              expandedItemId={expandedItemId}
              infoBlock={infoBlock}
              setVideoId={setVideoId}
              setExpandedItemId={setExpandedItemId}
            />
          ))}
        </div>
      </>
    );
  };

  const {
    heading: { title, subTitle },
  } = props.content.newsPage;

  return (
    <Fragment>
      <div className='newsPadded'>
        <div className='SubHeading'>{subTitle}</div>
        <div className='headContainer'>
          <h2 className='Heading'>{title}</h2>
          <Select options={categories} onChange={handleChangeCategory} />
        </div>
        <Pagination
          className='PaginationBar'
          currentPage={pagination.page}
          totalCount={pagination.total}
          pageSize={pagination.pageSize}
          onPageChange={(page: number) => setPagination({ ...pagination, page })}
        />
        <div className='NewsContainer'>{renderNewsContent()}</div>
        <Pagination
          className='PaginationBar'
          currentPage={pagination.page}
          totalCount={pagination.total}
          pageSize={pagination.pageSize}
          onPageChange={(page: number) => setPagination({ ...pagination, page })}
        />
        <Modal onClose={closeModal} show={!!videoId}>
          {videoId && <YouTubePlayer className='youtube-video' videoId={videoId} />}
        </Modal>
      </div>
    </Fragment>
  );
};

export default NewsPage;
