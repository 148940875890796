import React, { Component } from 'react';

import './styles.css';

type Props = {
    id: string;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class HashAnchor extends Component<Props, {}> {

    render () {
        return (
            <span className='HashAnchor'>
                <span {...this.props} />
            </span>
        );
    }

}
