import React, { Component } from 'react';
import { AppNotification } from '../../atoms';
import { withLanguageContext } from '../../../utils/LanguageContext';
import { SW } from '../../../constants';
import { Notifications } from '../../../utils/types';

import './styles.css';

export const TYPES = {
	COOKIE: 'cookiePolicy',
	PWA: 'pwaUpdate',
};

type Props = {
	content: {
		notifications: Notifications;
	}
};

type State = {
	activeNotifications: {};
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
class AppNotificationView extends Component<Props, State> {

	state = {
		activeNotifications: {
			[TYPES.COOKIE]: !localStorage.getItem(TYPES.COOKIE),
			[TYPES.PWA]: false,
		},
	}

	componentDidMount () {
		window.addEventListener(SW.updateEvent, this.handleServiceWorkerUpdate);
	}

	componentWillUnmount () {
		window.removeEventListener(SW.updateEvent, this.handleServiceWorkerUpdate);
	}

	handleClick = (notificationType: keyof Notifications) => {
		switch (notificationType) {
			case TYPES.PWA:
				return () => window.location.reload();
			case TYPES.COOKIE:
				localStorage.setItem(TYPES.COOKIE, Date.now().toString());
				this.setState({
					activeNotifications: {
						...this.state.activeNotifications,
						[TYPES.COOKIE]: false,
					},
				});
				break;
			default:
				break;
		}
	}

	handleServiceWorkerUpdate = () => {
		this.setState({
			activeNotifications: {
				...this.state.activeNotifications,
				[TYPES.PWA]: true,
			},
		});
	}

	renderNotification = (notificationType: keyof Notifications, notifications: Notifications) => {
		return <AppNotification
			key={notificationType}
				className={`AppNotificationView-notification ${notificationType}`}
				buttonLabel={
					notifications[notificationType].buttonLabel
				}
				onClick={() => {
					this.handleClick(notificationType);
				}
				}
			>
				{
					notifications[notificationType].content
				}
			</AppNotification>
	}

	render () {
		const notifications = this.props.content.notifications;
		const activeNotifications = Object.entries(this.state.activeNotifications)
			.filter(([, value]) => value)
			.map(([key]) => key);
		
		return (
			<div className='AppNotificationView'>
				{activeNotifications.map((notificationType) => {
						// @ts-ignore
						return this.renderNotification(notificationType, notifications)
					}
				)}
			</div>
		);
	}

}


export default withLanguageContext(AppNotificationView);
