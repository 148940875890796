/* eslint-disable max-len */
export default `# Cookie Policy
Last Updated May 19, 2020

## What is a cookie?

A cookie is a small file that stores internet settings. Almost every website uses cookie technology. It is downloaded by your internet browser on the first visit to a website. The next time this website is opened with the same user device, the cookie and the information stored in it is either sent back to the website that created it (first-party cookie) or sent to another website it belongs to (third-party cookie). This enables the website to detect that you have opened it previously with this browser and in some cases to vary the displayed content.

## Cookies not requiring consent on this website

Essential cookies, also referred to as “strictly necessary” cookies, ensure functions without which you would not be able to use this website as intended. These cookies are used exclusively by Taggis and are therefore so-called first-party cookies. They are only stored on your device during the current browser session.

## Cookies requiring consent on this website

1. **Cookies for user tracking.** Our website uses analytics tools to collect information as to how users interact with the website and its content. Taggis uses this information to ensure that the website creates added value for its visitors.
2. **Cookies for marketing.** Cookies for marketing purposes are used to extract advertisements that are relevant to specific users and adapted to their interests. They are also used to limit the frequency of appearance of an advertisement and to measure the effectiveness of advertising campaigns.
`;
