import React, { Component, createRef } from "react";
import { OptionType } from "../../../utils/types";
import { Option } from "../../atoms";

import "./styles.css";

type Props = {
  options: OptionType[];
  onChange: (option: OptionType) => void;
};

type State = {
  selected: OptionType;
  opened: boolean;
};

export default class Select extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selected: props.options[0],
      opened: false,
    };
  }

  selectRef = createRef<HTMLDivElement>();

  componentDidMount() {
    window.addEventListener('keydown', this.closeOnEscape);
    window.addEventListener('click', this.clickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.closeOnEscape);
    window.removeEventListener('click', this.clickOutside);
  }

  clickOutside = (e: MouseEvent) => {
    if (!this.selectRef.current || !e.target) return;

    if (this.state.opened && !this.selectRef.current.contains(e.target as Node)) {
      this.closeSelect();
    }
  }

  closeOnEscape = (e: KeyboardEvent) => {
    if (e.code === "Escape" && this.state.opened) {
      this.closeSelect();
    }
  }

  closeSelect() {
    this.setState({opened: false});;
  }

  onOpen = () => {
    this.setState({
      opened: !this.state.opened,
    });
  };

  onSelect = (optionKey: string) => {
    const option = this.findOption(optionKey);

    if (!option) return;

    this.setState(
      {
        selected: option,
        opened: false,
      },
      () => {
        this.props.onChange(option);
      }
    );
  };

  findOption(optionKey: string): OptionType | undefined {
    return this.props.options.find((option) => option.key === optionKey);
  }

  getOptions() {
    return this.props.options.map((o) => (
      <Option
        key={o.key}
        option={o}
        selected={this.state.selected}
        onSelect={this.onSelect}
      />
    ));
  }

  render() {
    let items = this.getOptions();

    return (
      <div className="Select" onClick={this.onOpen} ref={this.selectRef}>
        <span>{this.state.selected.value}</span>
        <ul
          className={`OptionsWrapper ${
            this.state.opened ? "OptionsWrapperWrapOpen" : ""
          }`}
        >
          {items}
        </ul>
      </div>
    );
  }
}
