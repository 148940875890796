import React, { Component, Fragment } from 'react';
import { genClassName } from '../../../utils/helpers';

import './styles.css';

type Props = {
    autoComplete?: string;
    label: string;
    innerRef?: React.Ref<HTMLInputElement>;
    name: string;
    type: string;
    animated?: boolean;
    onInput?: (event: React.SyntheticEvent) => void;
    value?: string;
    pattern?: string;
    required?: boolean;
    rows?: number;
    errorMessage?: string
};

type State = {
    animate: boolean;
    animateTimes: number;
    value: string;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class Input extends Component<Props, State> {
  state = {
    animate: false,
    animateTimes: 0,
    value: this.props.value || '',
  };

  handleInput = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ value: event.currentTarget.value });
    if (this.props.onInput) this.props.onInput(event);
  };

  handleFocus = () => {
    this.setState({ animate: true });
    setTimeout(() => {
      this.setState({ animate: false, animateTimes: 1 });
    }, 1500);
  };

  handleBlur = () => this.setState({ animate: false, animateTimes: 1 });

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value as string });
    }
  }

  render() {
    const { label, innerRef, type, errorMessage, animated, ...inputProps } = this.props;
    const { animate, animateTimes, value } = this.state;

    return (
      <>
        <label className={genClassName('Input', { focused: animate && animateTimes === 0, error: errorMessage })}>
          {type === 'textarea' ? (
            <textarea
              onChange={this.handleInput}
              {...{ value, ...inputProps }}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
            />
          ) : (
            <input
              {...inputProps}
              {...{ type, value }}
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              ref={innerRef}
            />
          )}
          <span className='label'>{label}</span>
          {animated ? (
            <Fragment>
              <span className='animated' />
              <span className='animated' />
              <span className='animated' />
              <span className='animated' />
            </Fragment>
          ) : null}
          <div className='Input-validationBar' />
        </label>
        {errorMessage && <span className='inputErrorMessage'>{errorMessage}</span>}
      </>
    );
  }
}
