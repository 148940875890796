import React, { Component } from 'react';
import ContentConverter from '../../../utils/contentConverter';
import { Button, PaddedSection } from '../../atoms';
import { genClassName } from '../../../utils/helpers';
import { ImageType } from '../../../utils/types';
import './styles.css';

type Props = {
    heading: string;
    className: string;
    images: ImageType[];
    buttonLabel?: string;
    href?: string;
    onClick?: (event: React.SyntheticEvent) => void;
    fourColumnGrid?: boolean;
};

const SUPPORTS_SMOOTH_SCROLL = typeof CSS !== 'undefined' && CSS.supports('scroll-behavior', 'smooth');

/**
 * Text with image and buttons
 *
 * @typedef {Object} Props
 *
 * @augments {Component<Props>}
 */
export default class TextImageThird extends Component<Props, {}> {
    anchorRef: HTMLElement | null | undefined;
    isHashLink = this.props.href && this.props.href.includes('#');

    componentDidMount () {
        if (!SUPPORTS_SMOOTH_SCROLL && this.isHashLink) this.setupSmoothScroll();
    }

    handleClick = (event: React.SyntheticEvent) => {
        if (this.anchorRef && this.anchorRef.parentElement && this.props.href) {
            event.preventDefault();

            /* NOTE: The following code:
             * 1: stores the current scroll position,
             * 2: sets the hash (so that the adress bar updates appropriately), and then
             * 3: scrolls to the correct position. */
            const prevScrollYPos = window.scrollY;

            const top = this.anchorRef.parentElement.offsetTop + this.anchorRef.offsetTop;

            window.location.hash = this.props.href;
            window.scrollTo(0, prevScrollYPos);
            requestAnimationFrame(() => window.scrollTo({ behavior: 'smooth', top }));
        }
    }

    setMatchingElement = (idWithHash: string) => {
        const id = idWithHash.substr(1);
        this.anchorRef = document.getElementById(id);
    }

    setupSmoothScroll = async () => {
        const smoothScroll = await import('smoothscroll-polyfill');
        smoothScroll.polyfill();
        if (this.props.href) this.setMatchingElement(this.props.href);
    }

    genContent = (content: string) => new ContentConverter(content).convertLinks().getReactHtml();

    genImages = (images: ImageType[]) => images.map((image: ImageType, key: number) => {
        const imageUrl = require (`../../../assets/svg/${image.src}`);
        return <div key={key}><img src={imageUrl} alt={`${image.alt}`} /></div>
    });

    genImagesContainerStyle = (fourColumnGrid: boolean) => genClassName("TextImageThird-images", {
        "fourColumnGrid": fourColumnGrid
    })

    render () {
        const {
            heading,
            className,
            images,
            buttonLabel,
            href,
        } = this.props;

        let props: { href?: string; onClick?: (event: React.SyntheticEvent) => void; } = {};
        if (href) {
            // For hash links.
            props = { href };
            if (!SUPPORTS_SMOOTH_SCROLL) props.onClick = this.handleClick;
        }

        return (
            <PaddedSection className={genClassName('TextImageThird', className)}>
                <h1>{ heading }</h1>
                <div className={this.genImagesContainerStyle(!!this.props.fourColumnGrid)}>
                    {this.genImages(images)}
                </div>
                { href && !this.isHashLink ? <Button className="TextImageThird-Button" href={href}>{buttonLabel}</Button> : null }
                { href && this.isHashLink ? <a className="Button TextImageThird-Button Button-link" {...props} href={href}>{buttonLabel}</a> : null }
            </PaddedSection>
        );
    }

}
