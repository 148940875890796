import React, { Component } from 'react';

import './styles.css';
import { Dialog, SimpleMarkdown } from '../../atoms';

type Props = {
    visible: boolean;
    onClose: () => void;
    children: string;
};

type State = {
    isVisible: boolean;
};

/**
 * Description
 *
 * @typedef {Object} Props
 * @prop {String} [example]
 *
 * @augments {Component<Props>}
 */
export default class TermsDialog extends Component<Props, State> {

    state = {
        isVisible: this.props.visible,
    }

    componentDidUpdate (prevProps: Props) {
        if (this.props.visible !== prevProps.visible) {
            this.setState({ isVisible: this.props.visible }); // eslint-disable-line react/no-did-update-set-state
        }
    }

    handleClose = () => {
        this.setState({ isVisible: false });
    }

    render () {
        return (
            <Dialog className='TermsDialog' onClose={this.props.onClose} visible={this.state.isVisible}>
                <button className='TermsDialog-button' onClick={this.handleClose} type='button' />
                <SimpleMarkdown extended={true}>
                    {this.props.children}
                </SimpleMarkdown>
            </Dialog>
        );
    }

}
